import { addDays, isAfter, isBefore } from "date-fns";
import { TimeOfDay, withTimeOfDay } from ".";

export class InvalidPeriodError extends Error {
  constructor(period: DatePeriod) {
    super(`Period ${period} is invalid. From should be after to.`);
  }
}

export interface DatePeriodArgs {
  from: Date;
  to: Date;
}

class DatePeriodBase {
  protected _from: Date;
  protected _to: Date;

  constructor(args: DatePeriodArgs) {
    this._from = args.from ?? new Date();
    this._to = args.to ?? new Date();
  }

  get from() {
    return this._from;
  }
  set from(val: Date) {
    this._from = val;
  }

  get to() {
    return this._to;
  }
  set to(val: Date) {
    this._to = val;
  }

  isWithin(date: Date) {
    return date >= this.from && date <= this.to;
  }

  overlaps(period: DatePeriod) {
    return (
      period.isWithin(this.from) ||
      period.isWithin(this.to) ||
      this.isWithin(period.from) ||
      this.isWithin(period.to)
    );
  }

  toJSON(): any {
    const { from, to } = this;
    return { from, to };
  }

  toString(): string {
    return `${this.from.toISOString()} - ${this.to.toISOString()}`;
  }
}

/**
 * Represents an intermediate state for DatePeriods.
 *
 * Should be converted to DatePeriod for validation of period.
 */
export class LenientDatePeriod extends DatePeriodBase {
  constructor(args: DatePeriodArgs) {
    super(args);
  }

  static fromTimePeriod(
    date: Date,
    start: TimeOfDay,
    end: TimeOfDay
  ): LenientDatePeriod {
    return new LenientDatePeriod({
      from: withTimeOfDay(date, start),
      to:
        end.getTotalSeconds() < start.getTotalSeconds()
          ? addDays(withTimeOfDay(date, end), 1)
          : withTimeOfDay(date, end),
    });
  }

  /**
   * Creates the strict version of DatePeriod
   *
   * if .from is larger than .to, converts to .to and the same for to
   */
  toStrict(): DatePeriod {
    const reverse = isAfter(this.from, this.to);
    return new DatePeriod({
      from: reverse ? this.to : this.from,
      to: reverse ? this.from : this.to,
    });
  }
}

export class DatePeriod extends DatePeriodBase {
  /**
   * @throws {InvalidPeriodError} if .from is larger than .to
   */
  constructor(args: DatePeriodArgs) {
    super(args);
    if (isAfter(this._from, this._to)) {
      throw new InvalidPeriodError(this);
    }
  }

  static fromTimePeriod(date: Date, start: TimeOfDay, end: TimeOfDay) {
    return new DatePeriodBase({
      from: withTimeOfDay(date, start),
      to:
        end.getTotalSeconds() < start.getTotalSeconds()
          ? addDays(withTimeOfDay(date, end), 1)
          : withTimeOfDay(date, end),
    });
  }

  override get from() {
    return this._from;
  }

  /**
   * @throws {InvalidPeriodError} if .from is larger than .to
   */
  override set from(val: Date) {
    if (isAfter(val, this._to)) {
      throw new InvalidPeriodError(this);
    }

    this._from = val;
  }

  override get to() {
    return this._to;
  }

  /**
   * @throws {InvalidPeriodError} if .from is larger than .to
   */
  override set to(val: Date) {
    if (isBefore(val, this._to)) {
      throw new InvalidPeriodError(this);
    }

    this._to = val;
  }

  toLenient(): LenientDatePeriod {
    return new LenientDatePeriod({ from: this.from, to: this.to });
  }
}
