import React from "react";
import {
  Bundle,
  ImageAsset,
  Product,
  SpecialPricePeriod,
  getDescription,
} from "@greeter/core";
import { Card, CardContent, CardHeader, CardTitle } from "../Card";
import { classNames } from "@greeter/util";
import { HorizontalLine } from "../HorizontalLine";
import { LazyCoverImage } from "@greeter/matter";

import css from "./ExpandableTablePackageCard.module.scss";

const priceFormatter = Intl.NumberFormat("da-DK");

export type ExpandableTablePackageCardProps = React.PropsWithChildren & {
  className?: string;
  style?: React.CSSProperties;
  isActive?: boolean;
  product: Bundle | Product;
  now: Date;
  specialPricePeriods: Array<SpecialPricePeriod>;
};

export function ExpandableTablePackageCard({
  className = "",
  isActive = false,
  product,
  specialPricePeriods,
  now,
  style,
}: ExpandableTablePackageCardProps) {
  const price = priceFormatter.format(
    Product.getPrice(product, specialPricePeriods, now)
  );
  return (
    <Card {...classNames(className, css.Card, isActive ? css.Active : "")}>
      <CardHeader className={css.CardHeader}>
        <LazyCoverImage
          src={
            product.coverAsset
              ? ImageAsset.findUriWithSizeOrDefault(
                  product.coverAsset,
                  "4x3-w512"
                )
              : product.coverUrl ?? ""
          }
        />
      </CardHeader>
      <CardContent className={css.CardContent}>
        <div className={css.MainContent}>
          <CardTitle size="md">{product.title}</CardTitle>
          <div style={{ position: "relative" }}>
            <div className={css.CardDescription}>
              <pre>{getDescription(product)}</pre>
            </div>
            <div className={css.Fade} />
          </div>
        </div>
        <HorizontalLine className={css.HorizontalLine} />
        <div className={css.Footer}>
          <div className={css.Price}>{price},-</div>
        </div>
      </CardContent>
    </Card>
  );
}
