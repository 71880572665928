import { z } from "zod";

import { CategorySchema } from "./Category";
import { PriceChangeSchema } from "./PriceChange";
import { ProductSchema } from "./Product";
import { TimeOfDay } from "@greeter/date";

export const MenuProductSchema = ProductSchema.extend({
  active: z.boolean().default(true),
  categories: z.array(z.string()).default([]),
  priceChanges: z.array(PriceChangeSchema).default([]),
  originalPrice: z.number().default(0),
});

export type MenuProduct = z.infer<typeof MenuProductSchema>;

export module MenuProduct {
  export function filterByCategory(categoryId: string) {
    return (p: MenuProduct) => p.categories.find((c) => c === categoryId);
  }

  export function priceAfterPriceChanges(
    mp: MenuProduct,
    tz: "Europe/Copenhagen" = "Europe/Copenhagen"
  ) {
    const now = TimeOfDay.fromDate(new Date());

    for (let i = 0; i < mp.priceChanges.length; i++) {
      const pc = mp.priceChanges[i];
      const from = pc.period.from.getTotalSeconds();
      const to = pc.period.to.getTotalSeconds();
      const nowAsSeconds = now.getTotalSeconds();
      if (nowAsSeconds >= from && nowAsSeconds <= to) {
        return pc.price;
      }
    }

    return mp.originalPrice;
  }
}

export const MenuSchema = z.object({
  categories: z.array(CategorySchema),
  products: z.array(MenuProductSchema),
  featuredCategoryId: z.string(),
});

export type Menu = z.infer<typeof MenuSchema>;
