import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Table, TableId, Venue } from "@greeter/core";
import { useEmitter } from "@greeter/event";
import { mapQuery, Query } from "@greeter/util";
import { enableLogs, logger, sirene, warner } from "@greeter/log";
import { CreateBookingPage } from "./CreateBookingPage";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import {
  useBookedTablesQuery,
  useBookingSettingsQuery,
  useBookingsQuery,
  useFloorPlanQuery,
  useGreeterEventsForVenueQuery,
  useLockedTablesQuery,
  useProductsByVenueQuery,
  useSpecialPricePeriods,
  useSpecialOpeningHoursQuery,
  useVenueQuery,
  useBundlesByVenueQuery,
} from "@greeter-guest/api/QueryHooks";
import { IonModal, useIonAlert, useIonRouter } from "@ionic/react";
import { useAuth, useSupportToast } from "@greeter/matter";
import { addYears } from "date-fns";
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import {
  CreatePaymentFlowOption,
  PaymentFlowEventsEnum,
} from "@capacitor-community/stripe";
import { Capacitor } from "@capacitor/core";
import { partial } from "lodash";
import { PaymentCancelledError, PaymentFailedError } from "./PaymentErrors";

import { retry } from "ts-retry-promise";
import { UseQueryResult } from "@tanstack/react-query";
import { getEnv } from "@greeter-guest/utility/ConfigHooks";
import {
  DeclineError,
  useStripeErrorHandler,
} from "@greeter-guest/components/Payments";
import { DatePeriod, TimeOfDay } from "@greeter/date";
import { useHistory, useParams } from "react-router-dom";
import { getDate, getNumber, getString, useSearchParams } from "@greeter/url";
import {
  BookingSummaryRoute,
  CreateBookingV2Route,
  Intent,
} from "@greeter-guest/utility/Routes";
import { useLogin } from "@greeter-guest/contexts/LoginProvider";
import { CartItem } from "@greeter/commerce";
import { useLoading } from "@greeter/loading";
import { findBooking } from "./Utility";
import { UpdateUserPageApiHandler } from "../UpdateUserPage/UpdateUserPageApiHandler";
import { useDetectTab } from "@greeter-guest/hooks";
import { useCustomerQuery } from "@greeter/guest-api-hooks";

const name = "[CreateBookingPageApiHandler]";

enableLogs();
const log = logger(name);
const error = sirene(name);
const warn = warner(name);

type Maybe<T> = T | undefined;

/**
 * URL for testing query params
 * http://localhost:4202/create-booking/f5b3ffbd-1a80-46c6-8db8-326ca01b9a60?cartItem=%5B%22755233bf-5da4-419d-a44f-89a30cccf6ab%22,%205%5D&
 */

interface Params {
  venueId: string;
  productId: string;
}

function useSimpleQuery<T>(reactQuery: UseQueryResult<T, Error>): Query<T> {
  return useMemo(() => mapQuery(reactQuery), [reactQuery]);
}

function getTime(search: URLSearchParams, key: string): TimeOfDay | undefined {
  const serializedTime = search.get("arrivalTime");

  if (!serializedTime) return;

  return TimeOfDay.parse(serializedTime);
}

function useBookingSearchParams() {
  const search = useSearchParams();
  const { venueId } = useParams<Params>();

  const productId = useMemo(() => getString(search, "productId"), [search]);
  const arrivalDate = useMemo(() => getDate(search, "arrivalDate"), [search]);
  const arrivalTime = useMemo(() => getTime(search, "arrivalTime"), [search]);
  const groupSize = useMemo(() => getNumber(search, "groupSize"), [search]);
  const tables = useMemo(() => getTableIds(search, "table"), [search]);
  const cartItems = useMemo(() => getCartItems(search, "cartItem"), [search]);
  const comment = useMemo(() => getComment(search, "comment"), [search]);

  // Stripe paramenter
  const redirectStatus = useMemo(
    () => getString(search, "redirect_status") as Maybe<"failed" | "success">,
    [search]
  );

  const scrollPosition = useMemo(
    () => getNumber(search, "scrollPosition"),
    [search]
  );
  const intent = useMemo(
    () => getString(search, "intent") as Maybe<Intent>,
    [search]
  );
  const bookingId = useMemo(() => getString(search, "bookingId"), [search]);

  const result = useMemo(() => {
    return {
      redirectStatus,
      productId,
      arrivalDate,
      arrivalTime,
      venueId,
      groupSize,
      comment,
      scrollPosition,
      tables,
      bookingId,
      intent,
      cart: cartItems,
    };
  }, [
    redirectStatus,
    productId,
    venueId,
    arrivalDate,
    arrivalTime,
    groupSize,
    scrollPosition,
    tables,
    cartItems,
    comment,
    intent,
    bookingId,
  ]);

  return result;
}

function getTableIds(search: URLSearchParams, key: string): TableId[] {
  const items = search.getAll(key);

  if (!items) return [];

  const tableIds = items.map((i) => JSON.parse(i));

  return tableIds;
}

function getCartItems(search: URLSearchParams, key = "cartItem"): CartItem[] {
  const items = search.getAll(key);

  if (!items) return [];

  const cartItems: CartItem[] = items.map((i) => JSON.parse(i));

  return cartItems;
}

function getComment(search: URLSearchParams, key = "comment"): string {
  const item = search.get(key);

  if (!item) return "";

  return item;
}

function setComment(search: URLSearchParams, value: string, key = "comment") {
  search.set(key, value);
}

function setCartItems(
  search: URLSearchParams,
  items: CartItem[],
  key = "cartItem"
) {
  search.delete(key);
  for (const item of items) {
    search.append(key, JSON.stringify(item));
  }
}

export function CreateBookingPageApiHandler() {
  const api = useDefaultGuestApi();
  const { stripe } = useCapacitorStripe();

  const {
    redirectStatus,
    productId,
    arrivalDate,
    arrivalTime,
    venueId,
    cart,
    scrollPosition,
    tables,
    comment,
    groupSize,
    intent,
    bookingId,
  } = useBookingSearchParams();
  const { isLoggedIn, auth } = useAuth();
  const { isCustomerComplete: isUserComplete } = useLogin();
  const supportToast = useSupportToast();

  const [_arrivalDate, setArrivalDate] = useState(arrivalDate);

  const env = getEnv();

  useEffect(() => {
    if (arrivalDate) setArrivalDate(arrivalDate);
  }, [arrivalDate]);

  const productsByVenueQuery = useBundlesByVenueQuery(venueId ?? "");
  const productSpecialPricePeriods = useSpecialPricePeriods(
    productsByVenueQuery?.data?.map((p) => p.id)
  );
  const simpleSpecialPricePeriods = useSimpleQuery(productSpecialPricePeriods);

  const bookingSettingsQuery = useBookingSettingsQuery(api, venueId);
  const specialOpeningHoursQuery = useSpecialOpeningHoursQuery(venueId);

  const upcomingEventsQuery = useGreeterEventsForVenueQuery(
    {
      page: 0,
      pageSize: 100,
      venueId: venueId ?? "",
    },
    { enabled: !!venueId }
  );
  const venueQuery = useVenueQuery(venueId);
  const floorPlanQuery = useFloorPlanQuery(venueId);
  const bookedTablesQuery = useBookedTablesQuery(venueId, _arrivalDate);
  const lockedTablesQuery = useLockedTablesQuery(venueId, _arrivalDate);

  const period = useMemo(
    () => new DatePeriod({ from: new Date(), to: addYears(new Date(), 1) }),
    []
  );
  const bookingsQuery = useBookingsQuery(api, { period: period });

  const createPayment = useCallback(
    async (bookingId: string) => {
      const booking = await api.fetchBooking(bookingId);

      if (booking.order) {
        const redirect = new CreateBookingV2Route(tab).route({
          arrivalDate: booking.period.from,
          venueId: venueId,
          intent: "check-payment",
          paymentToken: paymentToken,
        });
        const payment = await api.createPayment({
          orderId: booking.order.id,
          redirectUrl: redirect,
        });
        const ephKey = await api.fetchStripeEphemeralKey();

        return {
          bookingId: booking.id,
          paymentToken: payment.token,
          stripeCustomerId: ephKey.customerId,
          stripeEphemeralKey: ephKey.ephemeralKey,
        };
      }
    },
    [api]
  );

  /**
   * We store a Ref to get access to this variable instantly so checkout
   * does not need to wait for at batch update from the useState mechanism.
   *
   * Kind of cheating, I guess.
   */
  const bookingOpts = useRef<{ bookingId: string }>({ bookingId: "" });

  const handleCreateBooking = useCallback(
    async (args) => {
      const booking = await api.createBooking(args);
      if (bookingOpts.current) {
        bookingOpts.current.bookingId = booking.id;
      }
      bookingsQuery.refetch();

      return booking;
    },
    [api, bookingsQuery]
  );

  useEffect(
    function onBookings() {
      if (
        !bookingsQuery.isSuccess ||
        !venueQuery.isSuccess ||
        specialOpeningHoursQuery.type !== "done" ||
        !_arrivalDate
      ) {
        return;
      }

      const dp = Venue.createOpeningHoursDatePeriodForDate({
        specialOpeningHours: specialOpeningHoursQuery.data,
        openingHours: venueQuery.data.bookableOpeningHours,
        targetDate: _arrivalDate,
      });

      if (dp) {
        const booking = findBooking({
          openingHours: dp,
          bookings: bookingsQuery.data,
          date: _arrivalDate,
        });

        if (booking) {
          bookingOpts.current.bookingId = booking.id;
        }
      }
    },
    [bookingsQuery.data, venueQuery, arrivalDate]
  );

  const [alert] = useIonAlert();

  const [paymentToken, setPaymentToken] = useState<string | undefined>();

  const checkoutEventEmitter = useEmitter<
    { event: "success"; data: void } | { event: "error"; data: DeclineError }
  >();

  const { waitFor } = useLoading();

  const [intentStatus, setIntentStatus] = useState<
    "in progress" | "done" | "failed" | undefined
  >();

  useEffect(() => {
    if (redirectStatus === "failed") {
      warn("Redirect payment failed... Retry...");
    }
    if (intent !== "check-payment" && redirectStatus !== "failed") return;
    if (!bookingId) return;

    log(
      `Intent: "${intent}" detected... Checking booking with ID: "${bookingId}"...`
    );

    async function checkBookingAfterRedirectPayment() {
      checkoutEventEmitter.on("success", () => {
        if (bookingId) onDone(bookingId);
      });
      setIntentStatus("in progress");
      try {
        await waitFor(
          retry(
            async () => {
              const b = await api.fetchBooking(bookingId!);
              log("Polling booking", b);
              if (b.status === "pending") {
                checkoutEventEmitter.emit({
                  event: "success",
                  data: undefined,
                });
                return Promise.resolve();
              }
            },
            { retries: 10, backoff: "FIXED", delay: 2000 }
          ),
          "Tjekker status på booking efter betaling..."
        );

        setIntentStatus("done");
      } catch {
        checkoutEventEmitter.emit({ event: "error", data: "try_again_later" });
        setIntentStatus("failed");
      }
    }

    checkBookingAfterRedirectPayment();
  }, [intent]);

  const payForBooking = useCallback(
    async function payForBooking(bookingId: string) {
      try {
        log("Trying to pay for booking with id", bookingId);
        const bookingResult = await createPayment(bookingId);

        if (!bookingResult)
          throw new Error(
            `Failed to create payment for booking or no order added to the booking ${bookingId}`
          );

        const { stripeEphemeralKey, stripeCustomerId, paymentToken } =
          bookingResult;

        if (!Capacitor.isNativePlatform()) {
          setPaymentToken(paymentToken);
          log(name, "Is web, using the checkout element instead.");
          return await new Promise<void>((resolve, reject) => {
            const successHandle = checkoutEventEmitter.on("success", () =>
              resolve()
            );
            const errorHandle = checkoutEventEmitter.on("error", () =>
              reject()
            );
          });
        }

        log("Is native, running native Stripe version.");
        return await new Promise<void>((resolve, reject) => {
          const log = partial(console.log, name, "[Stripe]");
          log("Creating payment for", bookingResult);

          stripe.addListener(PaymentFlowEventsEnum.Failed, () => {
            log("Failed to pay for booking", bookingResult);
            supportToast("Kunne ikke gennemføre betalingen.");
            reject(new PaymentFailedError());
          });
          stripe.addListener(PaymentFlowEventsEnum.FailedToLoad, () => {
            log("Failed to load payment for booking", bookingResult);
            supportToast("Kunne ikke åbne betalingsvinduet.");
            reject(new PaymentFailedError());
          });
          stripe.addListener(PaymentFlowEventsEnum.Canceled, () => {
            log("Cancelled payment for booking", bookingResult);
            supportToast("Betalingen blev annuleret.");
            reject(new PaymentCancelledError());
          });

          const baseFlowOptions = {
            merchantDisplayName: "Greeter",
            enableGooglePay: false,
            enableApplePay: false,
            applePayMerchantId: "merchant.greeter.guest",
            paymentIntentClientSecret: paymentToken,
            customerId: stripeCustomerId,
            customerEphemeralKeySecret: stripeEphemeralKey,
            countryCode: "DK",
          };

          const flowOptions: CreatePaymentFlowOption = {
            ...baseFlowOptions,
          };

          log("Is native");
          stripe
            .createPaymentFlow(flowOptions)
            .then(stripe.presentPaymentFlow)
            // TODO: Customer should confirm payment method
            .then((r) => {
              alert(`Bekræft valg af betalings metode: ${r.cardNumber}`, [
                { text: "Nej" },
                {
                  text: "Ja",
                  handler: () => {
                    stripe
                      .confirmPaymentFlow()
                      .then(async () => {
                        await retry(
                          async () => {
                            const b = await api.fetchBooking(
                              bookingResult.bookingId
                            );
                            log("Polling booking", b);
                            if (b.status === "pending") {
                              return Promise.resolve();
                            }
                          },
                          { retries: 10, backoff: "FIXED", delay: 2000 }
                        );
                      })
                      .then(resolve)
                      .catch(() => reject(new PaymentFailedError()));
                  },
                },
              ]);
            });
        });
      } catch (e) {
        log("Failed to finish payment flow with exception", e);
        throw e;
      } finally {
        await bookingsQuery.refetch();
      }
    },
    [
      createPayment,
      stripe,
      alert,
      api,
      supportToast,
      bookingsQuery,
      checkoutEventEmitter,
    ]
  );

  const handleTableClick = useCallback(
    (area: string, table: Table) => {
      const params = { area: area, ...table };
      // FirebaseAnalytics.logEvent({
      //   name: "click",
      //   params: {
      //     ...params,
      //     event_name: "click_table",
      //     venue_id: venueId,
      //     venue_name: venueQuery.data?.name,
      //   },
      // });
    },
    [venueId, venueQuery.data]
  );

  const simpleBookingSettings = useSimpleQuery(bookingSettingsQuery);
  const simpleBookedTables = useSimpleQuery(bookedTablesQuery);
  const simpleVenue = useSimpleQuery(venueQuery);
  const simpleFloorPlan = useSimpleQuery(floorPlanQuery);
  const simpleProducts = useSimpleQuery(productsByVenueQuery);
  const simpleEvents = useSimpleQuery(upcomingEventsQuery);
  const simpleBookings = useSimpleQuery(bookingsQuery);

  const unavailableTables = useMemo((): Query<TableId[]> => {
    const result: TableId[] = [];

    if (bookedTablesQuery.isSuccess)
      bookedTablesQuery.data.forEach((bt) => result.push(bt));

    if (lockedTablesQuery.type === "done")
      lockedTablesQuery.data.forEach((lt) => result.push(lt.table));

    return { type: "done", data: result };
  }, [bookedTablesQuery, lockedTablesQuery]);

  const tab = useDetectTab();
  const history = useHistory();

  const onDone = useCallback(
    function onDone(bookingId: string) {
      // Make sure we're not rechecking the booking for no reason
      const search = new URLSearchParams(window.location.search);
      search.delete("intent");
      history.replace(window.location.pathname + search.toString());
      history.push(new BookingSummaryRoute(tab).route({ bookingId }));
    },
    [history]
  );

  const displayStripeErrorToast = useStripeErrorHandler();

  const onPaymentError = useCallback((err: DeclineError) => {
    checkoutEventEmitter.emit({
      event: "error",
      data: err,
    });
    setPaymentToken(undefined);
    displayStripeErrorToast(err);
  }, []);
  const onPaymentSuccess = useCallback(() => {
    setPaymentToken(undefined);
    checkoutEventEmitter.emit({
      event: "success",
      data: undefined,
    });
  }, []);
  const onPaymentCancel = useCallback(() => {
    setPaymentToken(undefined);
  }, []);

  const customerQuery = useCustomerQuery(api, { enabled: isLoggedIn });
  const simpleCustomerQuery = useSimpleQuery(customerQuery);

  return (
    <CreateBookingPage
      env={env}
      cart={cart}
      customer={simpleCustomerQuery}
      comment={comment}
      tables={tables}
      groupSize={groupSize}
      pickedProduct={productId}
      bookingSettings={simpleBookingSettings}
      arrivalDate={_arrivalDate}
      arrivalTime={arrivalTime}
      scrollPosition={scrollPosition}
      unavailableTables={unavailableTables}
      venueId={venueId}
      venue={simpleVenue}
      intent={intent}
      intentStatus={intentStatus}
      floorPlan={simpleFloorPlan}
      onSelectedDateChange={setArrivalDate}
      isLoggedIn={isLoggedIn}
      isUserComplete={isUserComplete}
      bookings={simpleBookings}
      products={simpleProducts}
      specialPricePeriods={simpleSpecialPricePeriods}
      greeterEvents={simpleEvents}
      specialOpeningHours={specialOpeningHoursQuery}
      onTableClick={handleTableClick}
      createBooking={handleCreateBooking}
      onPayForBooking={payForBooking}
      onDone={onDone}
      paymentToken={paymentToken}
      onPaymentError={onPaymentError}
      onPaymentSuccess={onPaymentSuccess}
      onPaymentCancel={onPaymentCancel}
      onCancelPayment={async (orderId) => {
        // TODO: Should restore the booking or mark it as stale if a new is created but previous wasnt payed for.
        // await api.deleteOrder(orderId);
        await Promise.all([
          bookingsQuery.refetch(),
          bookedTablesQuery.refetch(),
        ]);
      }}
    />
  );
}
