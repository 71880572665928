import {
  FloorPlan,
  GreeterEvent,
  Product,
  Venue,
  TableId,
  BookingSettings,
  LockedTable,
  Menu,
  TableServiceSettings,
  Booking,
  SpecialOpeningHours,
  SpecialPricePeriod,
  Bundle,
} from "@greeter/core";
import {
  IGuestApi,
  PagedByVenueRequest,
  PagedLocationRequest,
} from "@greeter/api";
import { partial } from "lodash";
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useDefaultGuestApi } from "./api";
import { useAuth } from "@greeter/matter";
import { DatePeriod, TimeOfDay } from "@greeter/date";
import { mapQuery } from "@greeter/util";
import { useMemo } from "react";
import { Query } from "@greeter/query";

export type Loadable<T> =
  | { status: "loading" }
  | { status: "error"; error: string }
  | { status: "success"; data: T };

// Working example for using Loadable with query result
// const d = match(productsByVenueQuery)
//     .with({ isLoading: true }, (q) => ({ status: "loading" } as OtherLoadable<Product[]>))
//     .with({ isError: true }, q => ({status: "error", error: "Failed to fetch products." } as OtherLoadable<Product[]>))
//     .with({ isSuccess: true }, q => ({ status: "success", data: q.data } as OtherLoadable<Product[]>) )
//     .run();

function useSimpleQuery<T>(reactQuery: UseQueryResult<T, Error>): Query<T> {
  return useMemo(() => Query.mapQuery(reactQuery), [reactQuery]);
}

const lockedTableKeys = {
  get: (venueId?: string, date?: Date) => [venueId, date],
};

export const useGreeterEventQuery = (
  id: string,
  options?: UseQueryOptions<GreeterEvent, Error, GreeterEvent, string[]>
) => {
  const proxy = useDefaultGuestApi();

  return useQuery({
    queryKey: ["greeterEvent", id],
    queryFn: partial(proxy.fetchGreeterEvent.bind(proxy), id),
    ...options,
  });
};

export const useGreeterEventsQuery = (
  args: PagedLocationRequest,
  options?: UseQueryOptions<GreeterEvent[], Error, GreeterEvent[], string[]>
) => {
  const proxy = useDefaultGuestApi();

  return useQuery({
    queryKey: ["greeterEvents", "upcoming"],
    queryFn: partial(proxy.fetchUpcomingGreeterEvents.bind(proxy), args),
    ...options,
  });
};

export const useGreeterEventsForVenueQuery = (
  args: PagedByVenueRequest,
  options?:
    | Partial<UseQueryOptions<GreeterEvent[], Error, GreeterEvent[], any[]>>
    | undefined
) => {
  const proxy = useDefaultGuestApi();

  return useQuery({
    queryKey: ["greeterEvents", args.venueId],
    queryFn: partial(
      proxy.fetchUpcomingGreeterEventsForVenue.bind(proxy),
      args
    ),
    ...options,
  });
};

export function useProductsByVenueQuery(
  venueId?: string,
  options?: UseQueryOptions<Product[], Error, Product[], string[]> | undefined
) {
  const proxy = useDefaultGuestApi();

  return useQuery({
    queryKey: ["products", venueId ?? ""],
    queryFn: partial(proxy.fetchProductsByVenue.bind(proxy), venueId!),
    enabled: !!venueId,
    ...options,
  });
}

export function useBundlesByVenueQuery(
  venueId?: string,
  options?: UseQueryOptions<Bundle[], Error, Bundle[], string[]> | undefined
) {
  const proxy = useDefaultGuestApi();

  return useQuery({
    queryKey: ["bundles", venueId ?? ""],
    queryFn: partial(proxy.fetchBundlesByVenue.bind(proxy), venueId!),
    enabled: !!venueId,
    ...options,
  });
}

export function useSpecialPricePeriods(
  productIds?: Array<string>,
  options?:
    | UseQueryOptions<
        Array<SpecialPricePeriod>,
        Error,
        Array<SpecialPricePeriod>,
        string[]
      >
    | undefined
) {
  const api = useDefaultGuestApi();
  return useQuery({
    queryKey: ["productSpecialPricePeriods", ...(productIds ?? [])],
    queryFn() {
      if (productIds) {
        return api.fetchSpecialPricePeriods(productIds);
      } else {
        return [];
      }
    },
    enabled: !!productIds,
    ...options,
  });
}

export function useSpecialOpeningHoursQuery(
  venueId?: string,
  options?:
    | UseQueryOptions<
        SpecialOpeningHours[],
        Error,
        SpecialOpeningHours[],
        string[]
      >
    | undefined
) {
  const api = useDefaultGuestApi();

  const q = useQuery({
    queryKey: ["venue", venueId ?? "", "specialOpeningHours"],
    queryFn: async () => {
      if (!!venueId) return await api.fetchSpecialOpeningHours(venueId);
      else return [];
    },
    enabled: !!venueId,
    ...options,
  });
  const simple = useSimpleQuery(q);

  return simple;
}

export const useVenueQuery = (
  venueId?: string,
  options?: UseQueryOptions<Venue, Error, Venue, any[]> | undefined
) => {
  const api = useDefaultGuestApi();
  const _options = useMemo(
    () => ({
      enabled: !!venueId,
      ...options,
    }),
    [options, venueId]
  );
  return useQuery({
    queryKey: ["venue", venueId],
    queryFn: partial(api.fetchVenue.bind(api), venueId ?? ""),
    ..._options,
  });
};

export const useFloorPlanQuery = (
  venueId?: string,
  options?: UseQueryOptions<FloorPlan, Error, FloorPlan, string[]>
) => {
  const proxy = useDefaultGuestApi();
  return useQuery({
    queryKey: ["floorplan", venueId ?? ""],
    queryFn: partial(proxy.fetchFloorPlan.bind(proxy), venueId!),
    enabled: !!venueId,
    ...options,
  });
};

const dateFormatter = Intl.DateTimeFormat("da-DK", {
  year: "numeric",
  day: "numeric",
  month: "numeric",
});

export const useBookedTablesQuery = (
  venueId?: string,
  selectedDate?: Date,
  options?:
    | UseQueryOptions<TableId[], Error, TableId[], (string | undefined)[]>
    | undefined
) => {
  const api = useDefaultGuestApi();

  return useQuery({
    queryKey: [
      "bookedTables",
      venueId,
      selectedDate && dateFormatter.format(selectedDate),
    ],
    queryFn: partial(api.fetchBookedTables.bind(api), venueId!, selectedDate!),
    enabled: !!venueId && !!selectedDate,
    refetchInterval: 2000,
    ...options,
  });
};

export const useLockedTablesQuery = (
  venueId?: string,
  date?: Date,
  options?: UseQueryOptions<LockedTable[], Error, LockedTable[], any[]>
) => {
  const api = useDefaultGuestApi();

  const q = useQuery({
    queryKey: lockedTableKeys.get(venueId, date),
    queryFn: async () => {
      if (venueId && date) return await api.fetchLockedTables(venueId, date);
      return [];
    },
    enabled: !!venueId && !!date,
    refetchInterval: 2000,
    ...options,
  });

  const simpleQuery = useMemo(() => mapQuery(q), [q]);

  return simpleQuery;
};

export type UseBookingsQueryArgs = {
  period?: DatePeriod;
};
export const useBookingsQuery = (
  api: IGuestApi,
  args: UseBookingsQueryArgs,
  options?: UseQueryOptions<Booking[], Error, Booking[], any[]>
) => {
  const { isLoggedIn } = useAuth();
  return useQuery({
    queryKey: ["bookings", args, isLoggedIn],
    queryFn: partial(api.fetchBookings.bind(api)),
    enabled: isLoggedIn,
    ...options,
  });
};

const defaultBookingSettings: BookingSettings = {
  buffer: TimeOfDay.parse("01:00:00"),
};

export const useBookingSettingsQuery = (
  api: IGuestApi,
  venueId?: string,
  options?:
    | UseQueryOptions<BookingSettings, Error, BookingSettings, any[]>
    | undefined
) => {
  return useQuery({
    queryKey: ["bookingSettings", venueId],
    queryFn: async () => {
      if (venueId) return await api.fetchBookingSettings(venueId);
      else return defaultBookingSettings;
    },
    // Abusing TimeOfDay as an interval
    placeholderData: defaultBookingSettings,
    ...options,
  });
};

export const useMenuQuery = (
  api: IGuestApi,
  venueId?: string,
  options?: UseQueryOptions<Menu, Error, Menu, any[]>
) => {
  return useQuery({
    queryKey: ["menu", venueId],
    queryFn: () => api.fetchMenu(venueId ?? ""),
    ...options,
  });
};

export const useTableServiceSettingsQuery = (
  api: IGuestApi,
  venueId?: string,
  options?:
    | Omit<
        UseQueryOptions<
          TableServiceSettings,
          Error,
          TableServiceSettings,
          (string | undefined)[]
        >,
        "queryKey" | "queryFn"
      >
    | undefined
) => {
  return useQuery({
    queryKey: ["tableServiceSettings", venueId],
    queryFn: async () => {
      if (venueId) return await api.fetchTableServiceSettings(venueId);

      return { mode: "open" } as TableServiceSettings;
    },
    placeholderData: { mode: "open" },
    ...options,
  });
};
