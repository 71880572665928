import { DatePeriod, DatePeriodSchema } from "@greeter/date";
import { z } from "zod";

export const ActivePeriodSchema = z.object({
  id: z.string(),
  period: DatePeriodSchema,
  productId: z.string(),
});
export type ActivePeriod = z.infer<typeof ActivePeriodSchema>;

export function emptyActivePeriod(): ActivePeriod {
  return {
    id: crypto.randomUUID(),
    period: new DatePeriod({ from: new Date(), to: new Date() }),
    productId: "",
  };
}

export const ActivePeriodsSchema = z.array(ActivePeriodSchema);
export type ActivePeriods = z.infer<typeof ActivePeriodsSchema>;
