import { LenientDatePeriod } from "@greeter/date";
import { upsert } from "@greeter/map";
import { z } from "zod";

export const SpecialPricePeriodSchema = z.object({
  id: z.string(),
  period: z.object({
    from: z.string().transform((s) => new Date(s)),
    to: z.string().transform((s) => new Date(s)),
  }),
  price: z.number(),
  productId: z.string(),
});
export type SpecialPricePeriod = z.infer<typeof SpecialPricePeriodSchema>;
export type SpecialPricePeriodInput = z.input<typeof SpecialPricePeriodSchema>;

export const SpecialPeriodPeriodsSchema = z.array(SpecialPricePeriodSchema);

export function indexSpecialPricePeriodByProductId(
  specialPricePeriods: Array<SpecialPricePeriod>
): Map<string, Array<SpecialPricePeriod>> {
  const index = new Map<string, Array<SpecialPricePeriod>>();

  for (const specialPricePeriod of specialPricePeriods) {
    upsert(index, specialPricePeriod.productId, specialPricePeriod);
  }

  return index;
}
