import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { VenueOverviewPageApiHandler } from "./VenueOverviewPageApiHandler";
import { usePersistentStore } from "@greeter/store";

export function VenueOverviewPageRouteHandler() {
  const location = useLocation();

  const [themeId, setThemeId] = useState<string>();
  const [activityId, setActivityId] = useState<string>();
  const [country, setCountry] = useState("Denmark");
  const [city, setCity] = useState("Aalborg");

  const store = usePersistentStore<string>();

  useEffect(() => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    // TODO: Turn into match through React Router match?
    const themeId = searchParams.get("themeId");
    if (themeId) setThemeId(themeId);

    const activityId = searchParams.get("activityId");
    if (activityId) setActivityId(activityId);

    const country = store.get("greeter.country");
    if (country) setCountry(country);

    const city = store.get("greeter.city");
    if (city) setCity(city);
  }, [location]);

  return (
    <VenueOverviewPageApiHandler
      onThemeChange={setThemeId}
      themeId={themeId}
      activityId={activityId}
      country={country}
      city={city}
      onCityChange={(city) => {
        store.save("greeter.city", city);
        setCity(city);
      }}
    />
  );
}
