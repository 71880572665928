import { z } from "zod";
import { DatePeriod, LenientDatePeriod } from "./DatePeriod";

export const dateStringToDate = z
  .string()
  .datetime()
  .transform((s) => new Date(s));

export const DatePeriodSchema = z
  .object({
    from: dateStringToDate,
    to: dateStringToDate,
  })
  .refine((d) => new DatePeriod(d))
  .transform((d) => new DatePeriod(d));

export const LenientDatePeriodSchema = z
  .object({
    from: dateStringToDate,
    to: dateStringToDate,
  })
  .refine((d) => new LenientDatePeriod(d))
  .transform((d) => new LenientDatePeriod(d));
