import { toDateOnlyString } from "@greeter/date";
import { formatRelative, subDays } from "date-fns";
import { da } from "date-fns/locale";
import {
  AreaWeeklyOpeningHours,
  SpecialOpeningHours,
  Table,
  TableId,
  Venue,
} from "@greeter/core";

export type AreaOpeningHoursStatusOptions = {
  now?: Date;
  timeZone?: string;
};
export type AreaOpeningHoursStatusResult = {
  /**
   * Human readable danish string that describes the distance to the next opening hours.
   * Intended to be used to be displayed in the UI
   */
  opensAt: string | "never";
  opensAtDate?: Date;
  isOpenNow: boolean;
};
export function areaOpeningHourStatus(
  anchorDate: Date,
  openingHours: AreaWeeklyOpeningHours,
  specialOpeningHours: SpecialOpeningHours[],
  opts?: AreaOpeningHoursStatusOptions
): AreaOpeningHoursStatusResult {
  opts ??= {};
  opts.timeZone ??= "Europe/Copenhagen";
  opts.now ??= new Date();

  const periods = Venue.createDatePeriodsFromOpeningHours({
    specialOpeningHours: specialOpeningHours,
    openingHours: openingHours,
    now: subDays(anchorDate, 1),
    range: 10,
  });

  // Prepare a simple map for easy O(N)-ish lookups in the next loop
  const sohMap = new Map<string, SpecialOpeningHours>();
  for (let i = 0, len = specialOpeningHours.length; i < len; i++) {
    const specialOpeningHour = specialOpeningHours[i];
    sohMap.set(
      toDateOnlyString(specialOpeningHour.period.from),
      specialOpeningHour
    );
  }

  for (let i = 0, l = periods.length; i < l; i++) {
    const period = periods[i];
    if (period.isWithin(anchorDate)) {
      return {
        opensAt: formatRelative(period.from, anchorDate, { locale: da }),
        opensAtDate: period.from,
        isOpenNow: true,
      };
    }
    if (period.from >= anchorDate) {
      return {
        opensAt: formatRelative(period.from, anchorDate, { locale: da }),
        opensAtDate: period.from,
        isOpenNow: false,
      };
    }
  }
  return { opensAt: "never", isOpenNow: false };
}

export function difference(a: TableId[], b: TableId[]) {
  return a.filter((bt) => !b.find((sbt) => Table.eqIds(sbt, bt)));
}
