import {
  Booking,
  SpecialOpeningHours,
  Venue,
  WeeklyOpeningHours,
} from "@greeter/core";
import {
  DateFactory,
  withTimeOfDay,
  TimeOfDay,
  Day,
  TimeOfDayPeriod,
  DatePeriod,
  LenientDatePeriod,
} from "@greeter/date";
import { ArgumentError } from "@greeter/error";
import { Res, Result } from "@greeter/result";
import { array } from "@greeter/util";
import {
  addMinutes,
  isAfter,
  addSeconds,
  isToday,
  isBefore,
  set,
  addHours,
  differenceInSeconds,
  isSameDay,
} from "date-fns";
import { useLocation } from "react-router-dom";

export type OpeningHours = Map<Day, TimeOfDayPeriod>;
export const isOpen = (day: Day, openingHours: OpeningHours) =>
  !!openingHours.get(day);

type IsStartTimeLaterThanArgs = {
  now: Date;
  forDate: Date;
  startTime: TimeOfDay;
};
const isNowLaterThanStartTime = ({
  now,
  forDate,
  startTime,
}: IsStartTimeLaterThanArgs) => {
  const isDateToday = isToday(forDate);

  const nowTimeOfDay = TimeOfDay.fromDate(now);
  const isNowLaterThanStartTime =
    isDateToday && nowTimeOfDay.isLaterThan(startTime);

  return isNowLaterThanStartTime;
};

type DetermineStartTimeArgs = {
  now: Date;
  forDate: Date;
  lowerLimitOffset: number;
  initialStartTime: TimeOfDay;
};
const determineStartTime = ({
  now,
  forDate,
  lowerLimitOffset,
  initialStartTime,
}: DetermineStartTimeArgs): [Date, TimeOfDay] => {
  const nowWithLowerLimitOffset = addMinutes(now, lowerLimitOffset);

  const isDateToday = isToday(forDate);
  const isNowLaterThanStart = isNowLaterThanStartTime({
    now: nowWithLowerLimitOffset,
    forDate,
    startTime: initialStartTime,
  });

  const isNextHour = nowWithLowerLimitOffset.getMinutes() > 30;

  /**
   * Normalizes the now time
   * For example a "now" time which has the time of day equal to 22:13:34
   * will become 22:30:00 and 22:46:23 will become 23:00:00 and so on.
   */
  const normalizedNow = addHours(
    set(now, { minutes: isNextHour ? 0 : 30, seconds: 0, milliseconds: 0 }),
    isNextHour ? 1 : 0
  );
  const startDateWithTime = isNowLaterThanStart
    ? normalizedNow
    : isDateToday
    ? withTimeOfDay(now, initialStartTime)
    : withTimeOfDay(forDate, initialStartTime);

  const startTime = isNowLaterThanStart
    ? TimeOfDay.fromDate(startDateWithTime)
    : initialStartTime;

  return [startDateWithTime, startTime];
};

type DiffAsDateArgs = { now: Date; start: TimeOfDay; end: TimeOfDay };
const diffAsDate = ({ now, start, end }: DiffAsDateArgs) =>
  addSeconds(
    withTimeOfDay(now, start),
    TimeOfDay.difference(start, end).getTotalSeconds()
  );

type DetermineEndTimeArgs = {
  now: Date;
  timePeriod: TimeOfDayPeriod;
  startTime: TimeOfDay;
  upperLimit: TimeOfDay;
};
const determineEndTime = ({
  now,
  timePeriod,
  startTime,
  upperLimit,
}: DetermineEndTimeArgs) => {
  const diffAsDateWithNowAndStartTime = (end: TimeOfDay) =>
    diffAsDate({ now, start: startTime, end });

  const startTimeOffsetByUpperLimitDifference =
    diffAsDateWithNowAndStartTime(upperLimit);
  const startTimeOffsetByEndTimeDifference = diffAsDateWithNowAndStartTime(
    timePeriod.to
  );

  const useUpperLimit = isBefore(
    startTimeOffsetByUpperLimitDifference,
    startTimeOffsetByEndTimeDifference
  );

  const endTime = useUpperLimit ? upperLimit : timePeriod.to;

  return endTime;
};

/**
 * @param timePeriods Create arrival times for a given weekly timeperiod
 * @param forDate Which date is this. This will be the basis for the start period
 * @param lowerLimitOffset What offset for the start TimeOfDay should be use?
 * @param upperLimit What is the upper limit for the arrival times? What TimeOfDay is the latest TimeOfDay?
 * @param interval What time spacing should there be between each generated date?
 */
type CreateArrivalTimesArgs = {
  now?: Date;
  timePeriod: TimeOfDayPeriod;
  forDate: Date;
  lowerLimitOffset?: number;
  upperLimit?: TimeOfDay;
};
export function createArrivalTimes({
  now,
  timePeriod,
  forDate,
  lowerLimitOffset = 15,
  upperLimit,
}: CreateArrivalTimesArgs): Date[] {
  now ??= DateFactory.create();
  const nowWithLowerLimitOffset = addMinutes(now, lowerLimitOffset);

  const [startDateWithTime, startTime] = determineStartTime({
    now,
    forDate,
    lowerLimitOffset,
    initialStartTime: timePeriod.from,
  });
  const endTime = determineEndTime({
    now,
    timePeriod,
    startTime,
    upperLimit: upperLimit ? upperLimit : timePeriod.to,
  });

  const generatedDates = generateDates({
    date: forDate,
    from: startTime,
    to: endTime,
    intervalInMinutes: 30,
  }).filter((d) =>
    isNowLaterThanStartTime({
      now: nowWithLowerLimitOffset,
      forDate,
      startTime,
    })
      ? isAfter(d, nowWithLowerLimitOffset)
      : true
  );

  return generatedDates;
}

export type GenerateDatesArgs = {
  date: Date;
  from: TimeOfDay;
  to: TimeOfDay;
  intervalInMinutes: number;
};
export function generateDates({
  date,
  from,
  to,
  intervalInMinutes,
}: GenerateDatesArgs) {
  const asMinutes = (seconds: number) => seconds / 60;
  const diff = TimeOfDay.difference(from, to);
  const steps = asMinutes(diff.getTotalSeconds()) / intervalInMinutes;
  const start = withTimeOfDay(date, from);

  const result = array(0, steps).map((i) => {
    return addMinutes(DateFactory.create(start), intervalInMinutes * i);
  });

  return result;
}

/**
 * @throws {Error} if unable to find a period overlap between now and periods
 */
export function createBookingPeriod2(
  arrivalTime: Date,
  periods: Array<LenientDatePeriod>
): DatePeriod {
  const period = periods.find((p) => p.isWithin(arrivalTime));

  if (!period)
    throw new Error(
      "You provided a now with no period. " +
        "Cannot create booking period. " +
        "Please provide a 'now' that is withing a DatePeriod in periods"
    );

  const result = new DatePeriod({ from: arrivalTime, to: period.to });

  return result;
}

export class BookingNotFoundError extends Error {
  constructor() {
    super("Could not find a booking for date");
    this.name = "BookingNotFoundError";
  }
}

export class ManyBookingsFoundError extends Error {
  constructor(public bookings: Booking[]) {
    super("Many bookings found, expected only one");
    this.name = "ManyBookingsFoundError";
  }
}

export class NoPeriodForBookingError extends Error {
  constructor(public openingHours: WeeklyOpeningHours, public date: Date) {
    super(`Could not create a period for the date ${date}`);
    this.name = "NoPeriodForBookingError";
  }
}

type FindExistingBookingErrors =
  | { code: "bookingNotFound"; data: BookingNotFoundError }
  | { code: "noPeriodForBooking"; data: NoPeriodForBookingError }
  | { code: "manyBookingsFound"; data: ManyBookingsFoundError }
  | { code: "argumentInvalid"; data: ArgumentError };

export type FindExistingBookingResult = Result<
  Booking,
  FindExistingBookingErrors
>;

export const findBookingForArrivalDate = (
  openingHours: LenientDatePeriod,
  bookings: Booking[],
  arrivalDate: Date
): FindExistingBookingResult => {
  if (!openingHours) {
    return Res.error({
      code: "argumentInvalid",
      data: new ArgumentError("openingHours is undefined"),
    });
  }
  if (bookings.length === 0) {
    return Res.error({
      code: "bookingNotFound",
      data: new BookingNotFoundError(),
    });
  }
  if (!arrivalDate) {
    return Res.error({
      code: "argumentInvalid",
      data: new ArgumentError("arrivalDate is undefined"),
    });
  }

  const notOverridable = bookings.filter(Booking.isNotOverridable);

  /**
   * We check whether or not it is within the opening hours period
   * so that we can account for days that span multiple days.
   *
   * We can also assume that the date that is input is within the opening hours
   * since they come from the CreateBookingPage and therefore must be within the opening hours.
   */
  const existingBookings = notOverridable.filter((b) => {
    return openingHours.isWithin(b.period.from);
  });

  if (existingBookings.length === 0) {
    return Res.error({
      code: "bookingNotFound",
      data: new BookingNotFoundError(),
    });
  }

  const result = Res.ok(existingBookings[0]);
  return result;
};

export function toRelativeRedirect(location: ReturnType<typeof useLocation>) {
  return `${location.pathname}${
    location.search && location.search !== "" ? `${location.search}` : ""
  }`;
}

export type FindBookingArgs = {
  openingHours: LenientDatePeriod;
  bookings: Array<Booking>;
  date: Date;
};
export function findBooking({ openingHours, bookings, date }: FindBookingArgs) {
  const result = findBookingForArrivalDate(openingHours, bookings, date);
  const existingBooking = Res.unwrapOrUndefined(result);
  return existingBooking;
}
