import React from "react";
import { classNames } from "@greeter/util";

import css from "./Button.module.scss";

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonProps = HTMLButtonProps;

export function Button({ className, ...props }: ButtonProps) {
  return <button {...classNames(css.Button, className)} {...props} />;
}
