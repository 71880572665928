/**
 * Mutates the map with key and array as value.
 *
 * Finds the key and the value then adds to the array.
 * If it doesnt exist, create a new array and add to the array.
 */
export function upsert<TKey, TValue>(
  map: Map<TKey, Array<TValue>>,
  k: TKey,
  v: TValue
) {
  const existing = map.get(k);

  if (existing) {
    existing.push(v);
  } else {
    map.set(k, [v]);
  }

  return map;
}

/**
 * Indexes an array by some property into a map.
 *
 * Make sure the key is unique as the next value will overrride the previous value.
 */
export function indexBy<T, TKey>(
  arr: Array<T>,
  by: (input: T) => TKey
): Map<TKey, T> {
  const m = new Map<TKey, any>();

  for (let i = 0, l = arr.length; i < l; i++) {
    const item = arr[i];
    m.set(by(item), item);
  }

  return m;
}
