import { Product, ProductSchema } from "./Product";
import { z } from "zod";

export const BundleLineVariantSchema = z.object({
  id: z.string(),
  name: z.string(),
  additionalPrice: z.number(), // Wrapping in an object since this allows for future multicurrency support.
  productId: z.string(),
});
export type BundleLineVariant = z.infer<typeof BundleLineVariantSchema>;

export const BundleLineSchema = z.object({
  id: z.string(),
  name: z.string(),
  quantity: z.number().gte(1).default(1),
  variants: z.array(BundleLineVariantSchema),
});
export type BundleLine = z.infer<typeof BundleLineSchema>;

export const BundleSchema = ProductSchema.extend({
  // TODO: This should be available to venues only
  isPublic: z.boolean().default(true),
  lines: z.array(BundleLineSchema),
  discountRate: z.optional(z.number().max(100).min(0).default(0)),
});
export const BundlesSchema = z.array(BundleSchema);
export type Bundle = z.infer<typeof BundleSchema>;
export type Bundles = z.infer<typeof BundlesSchema>;

export function emptyBundle(): Bundle {
  return {
    id: crypto.randomUUID(),
    title: "",
    subTitle: "",
    description: "",
    lines: [],
    price: 0,
    coverUrl: "",
    priority: 0,
    isPublic: true,
    categories: [],
  };
}

/**
 * Will summarize lines on bundle or the description.
 *
 * This is to support the old way of doing things and should be removed in the future
 *
 * TODO: Remove when new booking flow has stabilized
 */
export function getDescription(b: Bundle | Product): string {
  if (!("lines" in b)) {
    return b.description;
  }

  const lines = b.lines;

  if (!lines || lines.length === 0) {
    return b.description;
  }

  const description: Array<string> = [];
  for (let i = 0, l = lines.length; i < l; i++) {
    const line = lines[i];
    description.push(`${line.quantity} x ${line.name}`);
  }

  return description.join("\n");
}

export function isBundleDiscounted(b: Bundle): boolean {
  return !!b.discountPrice || !!b.discountRate;
}

export type BundlePrice =
  | { type: "regular"; price: number }
  | { type: "discounted"; price: number; discountedPrice: number };
/**
 * Function to calculate the actual price of a bundle;
 */
export function getBundlePrice(b: Bundle): BundlePrice {
  if (b.discountPrice) {
    return {
      type: "discounted",
      discountedPrice: b.discountPrice,
      price: b.price,
    };
  } else if (b.discountRate) {
    return {
      type: "discounted",
      discountedPrice: Number(
        (b.price * (1 - b.discountRate / 100)).toFixed(2)
      ),
      price: b.price,
    };
  }
  return { type: "regular", price: b.price };
}
