import {
  TableId,
  Venue,
  Booking,
  Product,
  SpecialOpeningHours,
  Customer,
  CustomerUtil,
  SpecialPricePeriod,
  Bundle,
} from "@greeter/core";
import { ReceiptLine, upsertQuantity } from "@greeter/matter";
import {
  assign,
  enqueueActions,
  raise,
  fromCallback,
  setup,
  assertEvent,
  emit,
} from "xstate";
import { LoginStatus } from "./Hooks";

import {
  addMinutes,
  addSeconds,
  differenceInMinutes,
  isSameDay,
  set,
} from "date-fns";
import {
  DatePeriod,
  TimeOfDay,
  toDateOnlyString,
  roundToNearestMinutes,
  LenientDatePeriod,
  LenientDatePeriodSchema,
  DatePeriodSchema,
} from "@greeter/date";
import { first } from "lodash";
import { CartItem } from "@greeter/commerce";

import { CreateBookingContext } from "./schema";

type ProductId = string;
// export type CreateBookingContext = {
//   loginStatus: LoginStatus;
//   customer?: Customer;
//   comment: string;
//   product?: Product;
//   products?: Product[];
//   productsById?: Map<string, Product>;
//   specialPricePeriods: Array<SpecialPricePeriod>;
//   /**
//    * Holds the product ids we've recently notified about.
//    *
//    * This is to prevent spamming notifications when we've already notified the user
//    * about a price change.
//    */
//   lastProductPriceChangesDetected: Set<ProductId>;
//   orderLines: ReceiptLine[];
//   orderTotal: number;
//   minTotal: number;
//   selectedTables: TableId[];
//   arrivalDate?: Date;
//   // Derived from the .from of openingHours.
//   arrivalDates?: Date[];
//   arrivalTime?: Date;
//   arrivalTimes: Date[];
//   specialOpeningHours: SpecialOpeningHours[];
//   openingHour?: DatePeriod;
//   openingHours: DatePeriod[];
//   groupSize?: number;
//   bookingBuffer?: TimeOfDay;
//   venue?: Venue;
//   now: Date;
//   booking?: Booking;
//   ticks: number;
//   bookings: Booking[];
//   bookingsByOpeningHoursFromDate: Map<string, Booking>;
// };

type SetCustomerEvent = {
  type: "setCustomer";
  data: Customer;
};

type SetSpecialOpeningHoursEvent = {
  type: "setSpecialOpeningHours";
  data: SpecialOpeningHours[];
};

type SetSelectedTablesEvent = {
  type: "setSelectedTables";
  data: TableId[];
};

type SetLoginStatusEvent = {
  type: "setLoginStatus";
  data: LoginStatus;
};

type ValidateEvent = {
  type: "validate";
};

type CreateBookingEvent = {
  type: "createBooking";
};

type BookingPayedEvent = {
  type: "bookingPayed";
};

type BookingPaymentFailedEvent = {
  type: "bookingPaymentFailed";
};

type BookingCreatedEvent = {
  type: "bookingCreated";
};

type CreateBookingFailedEvent = {
  type: "bookingCreationFailed";
};

type SetVenueEvent = {
  type: "setVenue";
  data: Venue;
};

type SetGroupSizeEvent = {
  type: "setGroupSize";
  data: number;
};

type SetArrivalDateEvent = {
  type: "setArrivalDate";
  data: Date;
};

type SetArrivalTimeEvent = {
  type: "setArrivalTime";
  data: Date;
};

type SetCommentEvent = {
  type: "setComment";
  data: string;
};

type SetProductEvent = {
  type: "setProduct";
  data: Bundle;
};

type SetQuantityEvent = {
  type: "setQuantity";
  data: number;
};

type SetBookingsEvent = {
  type: "setBookings";
  data: Booking[];
};

type SetBookingEvent = {
  type: "setBooking";
  data: Booking;
};

type SetBookingBufferEvent = {
  type: "setBookingBuffer";
  data: TimeOfDay;
};

type SetCartEvent = {
  type: "setCart";
  data: CartItem[];
};

type SetProductsEvent = {
  type: "setProducts";
  data: Array<Bundle>;
};

type SetMinTotalEvent = {
  type: "setMinTotal";
  data: number;
};

type EditEvent = {
  type: "edit";
};

type PayEvent = {
  type: "pay";
  data?: Booking;
};

type TickEvent = {
  type: "tick";
  data: Date;
};

type ErrorEvent = {
  type: "error";
};

type FinishEvent = {
  type: "finish";
};

type NextEvent = {
  type: "next";
};

type CheckPaymentEvent = {
  type: "checkPayment";
};

type SetOpeningHourEvent = {
  type: "setOpeningHour";
  data: DatePeriod;
};

type CancelEvent = {
  type: "cancel";
};

type SetSpecialPricePeriods = {
  type: "setSpecialPricePeriods";
  data: Array<SpecialPricePeriod>;
};

export type CreateBookingEvents =
  | TickEvent
  | ErrorEvent
  | EditEvent
  | SetCustomerEvent
  | SetSpecialPricePeriods
  | SetSelectedTablesEvent
  | SetLoginStatusEvent
  | SetSelectedTablesEvent
  | SetGroupSizeEvent
  | SetArrivalDateEvent
  | SetArrivalTimeEvent
  | SetMinTotalEvent
  | SetCommentEvent
  | SetVenueEvent
  | SetProductEvent
  | SetQuantityEvent
  | SetBookingsEvent
  | SetBookingEvent
  | SetBookingBufferEvent
  | SetCartEvent
  | SetSpecialOpeningHoursEvent
  | SetProductsEvent
  | SetMinTotalEvent
  | SetOpeningHourEvent
  | CheckPaymentEvent
  | ValidateEvent
  | CreateBookingEvent
  | CreateBookingFailedEvent
  | NextEvent
  | PayEvent
  | BookingCreatedEvent
  | BookingPayedEvent
  | BookingPaymentFailedEvent
  | CancelEvent
  | FinishEvent;

type DateOnlyString = string;

function bookingsByOpeningHoursMap(
  bookings: Array<Booking>,
  openingHours: Array<LenientDatePeriod>
) {
  const result = new Map<DateOnlyString, Booking>();

  for (let i = 0, l = openingHours.length; i < l; i++) {
    const openingHour = openingHours[i];
    const booking = bookings.find((b) => openingHour.isWithin(b.period.from));
    if (booking) {
      result.set(toDateOnlyString(openingHour.from), booking);
    }
  }

  return result;
}

export function arrivalTimesForPeriod({
  period,
  lowerBounds,
}: {
  period: LenientDatePeriod;
  lowerBounds: Date;
}): Array<Date> {
  let _lowerBounds = roundToNearestMinutes(lowerBounds, 30);
  const diffInMinutes = differenceInMinutes(period.to, period.from);

  const arrivalTimes: Array<Date> = [];
  for (let i = 0; i <= diffInMinutes; i += 30) {
    let date = addMinutes(period.from, i);
    arrivalTimes.push(date);
  }

  return arrivalTimes.filter((d) => d >= _lowerBounds);
}

export function arrivalTimesForOpeningHours({
  periods,
  lowerBounds,
}: {
  periods: Array<DatePeriod>;
  lowerBounds: Date;
}): Map<DateOnlyString, Array<Date>> {
  if (periods.length === 0) return new Map();

  let _lowerBounds = roundToNearestMinutes(lowerBounds, 30);

  const result: Map<DateOnlyString, Array<Date>> = new Map();

  for (const period of periods) {
    // Generate the arrival time with 30 minute spacing
    const arrivalTimes: Array<Date> = [];
    for (let i = 0; i <= 0; i += 30) {
      let date = addMinutes(period.from, i);
      arrivalTimes.push(date);
    }

    result.set(
      toDateOnlyString(period.from),
      arrivalTimes.filter((d) => d >= _lowerBounds)
    );
  }

  return result;
}

export function applyBookingBuffer(d: Date, buffer: TimeOfDay) {
  // Rounds to nearest 30 minutes
  let _now = addSeconds(d, buffer.getTotalSeconds());
  return roundToNearestMinutes(_now, 30);
}

export function updateArrivalTimes({
  arrivalDate,
  arrivalTime,
  now,
  openingHours,
  bookingBuffer,
}: Pick<
  CreateBookingContext,
  "arrivalDate" | "arrivalTime" | "openingHours" | "bookingBuffer" | "now"
>): Partial<CreateBookingContext> {
  if (openingHours.length === 0 || !arrivalDate)
    return { arrivalTimes: [], arrivalTime: undefined };

  const period = openingHours.find((oh) => isSameDay(oh.from, arrivalDate));

  if (!period) return { arrivalTimes: [], arrivalTime: undefined };

  let _now = now;
  if (bookingBuffer) {
    _now = applyBookingBuffer(_now, bookingBuffer);
  }

  const arrivalTimes = arrivalTimesForPeriod({
    lowerBounds: _now,
    period: period,
  });

  // Figure out which arrivalTime to pick as default.
  const earliest = first(arrivalTimes);
  const latest = arrivalTimes.at(-1);

  if (arrivalTime && earliest && latest) {
    const earliestLatestPeriod = new DatePeriod({ from: earliest, to: latest });
    return {
      arrivalTimes: arrivalTimes,
      arrivalTime: earliestLatestPeriod.isWithin(arrivalTime)
        ? arrivalTime
        : earliestLatestPeriod.from,
    };
  }

  return {
    arrivalTimes: arrivalTimes,
    arrivalTime:
      arrivalTime && isSameDay(arrivalTime, arrivalDate)
        ? arrivalTime
        : first(arrivalTimes),
  };
}

export const createBookingMachine = setup({
  types: {} as {
    context: CreateBookingContext;
    events: CreateBookingEvents;
    emitted:
      | { type: "loggingIn" }
      | { type: "updatingCustomerInfo" }
      | { type: "creatingBooking"; data: CreateBookingContext }
      | { type: "creatingPayment"; data: Booking }
      | { type: "paying"; data: Booking }
      | {
          type: "priceChangeDetected";
          data: Array<Pick<Product, "id" | "title">>;
        };
  },
  actors: {
    ticker: fromCallback(function ticker({ sendBack }) {
      function tick() {
        sendBack({ type: "tick", data: new Date() });
      }
      tick();
      setInterval(tick, 2_000);
    }),
  },
  guards: {
    isNotLoggedIn({ context }) {
      return context.loginStatus === "not logged in";
    },
    isLoggedInAndHasInfo({ context }) {
      return (
        context.loginStatus === "logged in" &&
        CustomerUtil.isComplete(context.customer)
      );
    },
    isIncompleteCustomer({ context }) {
      const isComplete = !CustomerUtil.isComplete(context.customer);
      return isComplete;
    },
    isCompleteCustomer({ context }) {
      const result = CustomerUtil.isComplete(context.customer);
      return result;
    },
    isPayable({ context }) {
      const result =
        !!context.booking &&
        Booking.is(context.booking, "created", context.now);
      return result;
    },
    isUserLoggedOut({ context }) {
      return context.loginStatus === "not logged in";
    },
    isUserIncomplete({ context }) {
      return context.loginStatus === "incomplete user";
    },
    isTablesValid({ context }) {
      return context.selectedTables?.length <= 0;
    },
    isOrderLinesValid({ context }) {
      return context.orderLines?.length <= 0;
    },
    isTotalValid({ context }) {
      return context.minTotal > context.orderTotal;
    },
    canPayForBooking({ context: { booking, venue, now } }) {
      const result =
        !!booking &&
        !!venue &&
        Booking.is(booking, "created", now) &&
        booking.venueId === venue.id;
      return result;
    },
    isBookingAlreadyPayedFor({ context: { booking, venue, now } }) {
      return (
        !!booking &&
        !!venue &&
        (Booking.is(booking, "accepted", now) ||
          Booking.is(booking, "inProgress", now) ||
          booking.status === "pending" ||
          booking.status === "accepted") &&
        booking.venueId === venue.id
      );
    },
    bookingExistsOtherVenue({ context: { booking, venue } }) {
      return !!booking && !!venue && booking.venueId !== venue.id;
    },
  },
  actions: {
    setCustomer: assign(function setCustomer({ event }) {
      assertEvent(event, "setCustomer");
      return { customer: event.data };
    }),
    setProduct: assign(function setProduct({ event }) {
      assertEvent(event, "setProduct");
      return { product: event.data };
    }),
    setProducts: assign(function setProducts({ event }) {
      assertEvent(event, "setProducts");
      const productsById = new Map<string, Bundle>();

      for (const product of event.data) {
        productsById.set(product.id, product);
      }

      return { products: event.data, productsById };
    }),
    setMinTotal: assign(function setMinTotal({ event }) {
      assertEvent(event, "setMinTotal");
      return { minTotal: event.data };
    }),
    setSelectedTables: assign(function setSelectedTables({ event }) {
      assertEvent(event, "setSelectedTables");
      return { selectedTables: event.data };
    }),
    setArrivalTime: assign(function setArrivalTime({ event }) {
      assertEvent(event, "setArrivalTime");
      return { arrivalTime: event.data };
    }),
    recalculateOrderLines: assign(({ context }) => {
      const newOrderLines: ReceiptLine[] = [];
      let total = 0;
      for (let i = 0, l = context.orderLines.length; i < l; i++) {
        const line = context.orderLines[i];
        let unitPrice = line.unitPrice;

        if (line.productId && context.productsById) {
          const product = context.productsById.get(line.productId);

          if (product) {
            unitPrice = Product.getPrice(
              product,
              context.specialPricePeriods,
              context.arrivalTime
            );
          }
        }
        newOrderLines.push({
          ...line,
          unitPrice: unitPrice,
        });
        total += unitPrice * line.quantity;
      }

      return {
        orderLines: newOrderLines,
        orderTotal: total,
      };
    }),
    setGroupSize: assign(function setGroupSize({ event }) {
      assertEvent(event, "setGroupSize");
      return { groupSize: event.data };
    }),
    setComment: assign(function setComment({ event }) {
      assertEvent(event, "setComment");
      return { comment: event.data };
    }),
    detectPriceChange: enqueueActions(({ context, enqueue }) => {
      const bookingDateTime = context.arrivalTime;
      if (!bookingDateTime) return;

      // Where the key is product id
      const withinPricePeriods: Array<SpecialPricePeriod> = [];
      for (const pricePeriod of context.specialPricePeriods) {
        if (
          bookingDateTime >= pricePeriod.period.from &&
          bookingDateTime <= pricePeriod.period.to
        ) {
          withinPricePeriods.push(pricePeriod);
        }
      }

      console.debug(
        "[detectPriceChange] withinPricePeiriods",
        withinPricePeriods
      );

      const productsById = context.productsById;
      if (!productsById) {
        console.warn("Cannot detect price changes if productsById is not set.");
        return;
      }
      const priceChangedProducts: Array<{ id: string; title: string }> = [];

      for (const l of context.orderLines) {
        if (!l.productId) continue;

        const product = productsById.get(l.productId);
        if (!product) continue;

        for (const pricePeriod of withinPricePeriods) {
          if (
            pricePeriod.productId === product.id &&
            pricePeriod.price !== product.price
          ) {
            priceChangedProducts.push({
              id: product.id,
              title: product.title,
            });
          }
        }
      }

      const notAlreadyDetected = priceChangedProducts.filter((pcp) => {
        return !context.lastProductPriceChangesDetected.has(pcp.id);
      });

      console.debug(
        "[detectPriceChange]",
        context.lastProductPriceChangesDetected,
        priceChangedProducts,
        notAlreadyDetected
      );

      if (notAlreadyDetected.length > 0) {
        enqueue.assign({
          lastProductPriceChangesDetected: new Set(
            priceChangedProducts.map((pcp) => pcp.id)
          ),
        });
        enqueue.emit({
          type: "priceChangeDetected",
          data: priceChangedProducts,
        });
      }

      if (priceChangedProducts.length === 0) {
        enqueue.assign({
          lastProductPriceChangesDetected: new Set(),
        });
      }
    }),
    setArrivalDate: assign(function setArrivalDate({
      context: { openingHours, bookingsByOpeningHoursFromDate },
      event,
    }) {
      assertEvent(event, "setArrivalDate");

      const foundOpeningHour = openingHours?.find((oh) =>
        isSameDay(oh.from, event.data)
      );

      const result: Partial<CreateBookingContext> = {
        arrivalDate: event.data,
      };

      if (foundOpeningHour) {
        result.openingHour = foundOpeningHour;
      }

      if (foundOpeningHour) {
        result.arrivalTime = foundOpeningHour.from;
      }

      result.booking = bookingsByOpeningHoursFromDate.get(
        toDateOnlyString(event.data)
      );

      return result;
    }),
    updateArrivalTimes: assign(({ context }) => {
      return updateArrivalTimes(context);
    }),
    updateDatePeriods: assign(function updateDatePeriods({ context }) {
      if (!context.venue) return {};

      let periods = Venue.createDatePeriodsFromOpeningHours({
        specialOpeningHours: context.specialOpeningHours,
        openingHours: context.venue?.bookableOpeningHours,
        range: 360,
        now: context.now,
      });

      const firstPeriod = periods.at(0);
      if (firstPeriod) {
        const arrivalTimesForFirst = arrivalTimesForPeriod({
          period: firstPeriod,
          lowerBounds: context.bookingBuffer
            ? applyBookingBuffer(context.now, context.bookingBuffer)
            : context.now,
        });

        // Cut off the first one if no arrivalTimes.
        // This date should then be unbookable, i.e. removed
        if (arrivalTimesForFirst.length === 0) {
          periods = periods.slice(1);
        }
      }

      const result: Partial<CreateBookingContext> = {
        openingHours: periods,
      };

      if (context.bookings) {
        result.bookingsByOpeningHoursFromDate = bookingsByOpeningHoursMap(
          context.bookings,
          periods
        );
      }

      if (context.arrivalDate) {
        result.openingHour = periods.find((oh) =>
          isSameDay(oh.from, context.arrivalDate!)
        );
      }

      return result;
    }),

    setCart: assign(function setCart({ context, event }) {
      assertEvent(event, "setCart");
      const receiptLines: ReceiptLine[] = event.data
        .map(([productId, quantity]) => {
          const foundProduct = context.products?.find(
            (p) => p.id === productId
          );

          if (!foundProduct) return undefined;

          const line: ReceiptLine = {
            title: foundProduct.title,
            unitPrice: foundProduct.price,
            quantity: quantity,
          };

          return line;
        })
        .filter(Boolean) as ReceiptLine[];

      let total = 0;
      for (let i = 0, l = receiptLines.length; i < l; i++) {
        const line = receiptLines[i];
        total += line.unitPrice * line.quantity;
      }

      return { orderLines: receiptLines, orderTotal: total };
    }),

    setQuantity: assign(function setQuantity({
      context: {
        product,
        orderLines,
        specialPricePeriods,
        arrivalTime,
        productsById,
      },
      event,
    }) {
      assertEvent(event, "setQuantity");
      if (!product) return {};

      // TODO: Recalculation after updating changing date?
      const newOrderLines = upsertQuantity(
        orderLines,
        product.id,
        product.title,
        Product.getPrice(product, specialPricePeriods, arrivalTime),
        event.data
      );

      console.debug("New order lines", newOrderLines);

      let total = 0;
      for (const line of newOrderLines) {
        let unitPrice = line.unitPrice;

        if (line.productId && productsById) {
          const product = productsById.get(line.productId);

          if (product) {
            unitPrice = Product.getPrice(
              product,
              specialPricePeriods,
              arrivalTime
            );
          }
          total += unitPrice * line.quantity;
        }
      }

      return { orderLines: newOrderLines, orderTotal: total };
    }),

    setVenue: assign(function setVenue({ event }) {
      assertEvent(event, "setVenue");
      return { venue: event.data };
    }),

    setBookingBuffer: assign(function setBookingBuffer({ event }) {
      assertEvent(event, "setBookingBuffer");
      return { bookingBuffer: event.data };
    }),

    setSpecialOpeningHours: assign(function setSpecialOpeningHours({ event }) {
      assertEvent(event, "setSpecialOpeningHours");
      return { specialOpeningHours: event.data };
    }),

    tick: assign(function tick({ context: { ticks }, event }) {
      assertEvent(event, "tick");
      return { ticks: ticks + 1, now: event.data };
    }),

    setBookings: assign(function setBookings({ context, event }) {
      assertEvent(event, "setBookings");
      const result: Partial<CreateBookingContext> = {
        bookings: event.data,
      };

      if (context.openingHours) {
        const bookingsByOpeningHoursFromDate = bookingsByOpeningHoursMap(
          event.data,
          context.openingHours
        );
        result.bookingsByOpeningHoursFromDate = bookingsByOpeningHoursFromDate;
      }

      if (!context.arrivalDate) return result;

      result.booking = result.bookingsByOpeningHoursFromDate?.get(
        toDateOnlyString(context.arrivalDate)
      );

      return result;
    }),

    setBooking: assign(function setBooking({ event }) {
      assertEvent(event, "setBooking");
      return { booking: event.data };
    }),

    setLoginStatus: assign(function setLoginStatus({ event }) {
      assertEvent(event, "setLoginStatus");
      return {
        loginStatus: event.data,
      };
    }),

    setSpecialPricePeriods: assign(({ event }) => {
      assertEvent(event, "setSpecialPricePeriods");
      return { specialPricePeriods: event.data };
    }),

    ensureDateHasArrivalTimes: assign(function ensureDateHasArrivalTimes({
      context,
    }) {
      if (context.arrivalTimes.length !== 0 || !context.arrivalDate) {
        return {};
      }

      const currentIdx = context.openingHours.findIndex((oh) =>
        isSameDay(context.arrivalDate!, oh.from)
      );
      if (currentIdx === -1) return {};

      const nextDate = context.openingHours.at(currentIdx + 1);
      if (!nextDate) {
        return {};
      }

      return {
        arrivalDate: nextDate.from,
      };
    }),
  },
}).createMachine({
  id: "create-booking",
  initial: "validating",
  context: {
    loginStatus: "not logged in",
    orderLines: [],
    orderTotal: 0,
    comment: "",
    minTotal: 0,
    venue: undefined,
    product: undefined,
    specialPricePeriods: [],
    lastProductPriceChangesDetected: new Set<string>(),
    groupSize: 2,
    arrivalDate: undefined,
    arrivalTime: undefined,
    arrivalTimes: [],
    specialOpeningHours: [],
    openingHours: [],
    selectedTables: [],
    booking: undefined,
    ticks: 0,
    now: new Date(),
    bookings: [],
    bookingsByOpeningHoursFromDate: new Map(),
  },
  invoke: { src: "ticker" },
  on: {
    tick: {
      actions: [
        "tick",
        "updateArrivalTimes",
        "detectPriceChange",
        "recalculateOrderLines",
        "ensureDateHasArrivalTimes",
        "updateArrivalTimes",
        "updateDatePeriods",
      ],
    },
    setArrivalTime: {
      actions: ["setArrivalTime", "detectPriceChange", "recalculateOrderLines"],
    },
    setArrivalDate: {
      actions: [
        "setArrivalDate",
        "detectPriceChange",
        "recalculateOrderLines",
        "updateArrivalTimes",
        "ensureDateHasArrivalTimes",
        "updateArrivalTimes",
        "updateDatePeriods",
      ],
    },
    setBookingBuffer: { actions: "setBookingBuffer" },
    setSpecialOpeningHours: { actions: "setSpecialOpeningHours" },
    setSpecialPricePeriods: { actions: "setSpecialPricePeriods" },
    setCustomer: { actions: "setCustomer" },
    setLoginStatus: { actions: "setLoginStatus" },
  },
  states: {
    loggingIn: {
      entry: emit({ type: "loggingIn" }),
      on: {
        cancel: "validating",
        setCustomer: { actions: ["setCustomer", raise({ type: "next" })] },
        next: [
          {
            target: "updatingCustomerInfo",
            guard: "isIncompleteCustomer",
          },
          "creatingBooking",
        ],
      },
    },
    updatingCustomerInfo: {
      entry: emit({ type: "updatingCustomerInfo" }),
      on: {
        cancel: "validating",
        setCustomer: {
          actions: ["setCustomer", raise({ type: "next" })],
        },
        next: {
          target: "creatingBooking",
          guard: "isCompleteCustomer",
        },
      },
    },
    invalidTables: {
      on: { edit: "editing" },
    },
    invalidOrderLines: {
      on: { edit: "editing" },
    },
    invalidTotal: {
      on: { edit: "editing" },
    },
    bookingExistsOtherVenue: {
      on: { edit: "uneditable" },
    },
    bookingAlreadyPayedFor: {
      on: { edit: "uneditable" },
    },
    requiresPayment: {
      on: {
        edit: "uneditable",
        setBookings: { actions: ["setBookings", "updateDatePeriods"] },
        setBooking: { actions: "setBooking" },
        pay: [
          {
            target: "loggingIn",
            guard: "isNotLoggedIn",
          },
          {
            target: "creatingPayment",
            guard: "isLoggedInAndHasInfo",
          },
          "validating",
        ],
      },
    },
    uneditable: {
      id: "uneditable",
      on: {
        setBookings: { actions: "setBookings" },
        setBooking: { actions: "setBooking" },
        setProduct: { actions: "setProduct" },
        setMinTotal: { actions: "setMinTotal" },
        setGroupSize: { actions: "setGroupSize" },
        setComment: { actions: "setComment" },
        setSelectedTables: { actions: "setSelectedTables" },
        validate: "validating",
      },
    },
    editing: {
      on: {
        setSpecialPricePeriods: { actions: "setSpecialPricePeriods" },
        setProducts: { actions: "setProducts" },
        setCart: { actions: "setCart" },
        setSelectedTables: { actions: "setSelectedTables" },
        setMinTotal: { actions: "setMinTotal" },
        setVenue: { actions: ["setVenue", "updateDatePeriods"] },
        setGroupSize: { actions: "setGroupSize" },
        setComment: { actions: "setComment" },
        setQuantity: { actions: "setQuantity" },
        setProduct: { actions: "setProduct" },
        setBookings: { actions: "setBookings" },
        validate: "validating",
      },
    },
    validating: {
      entry: raise({ type: "validate" }),
      on: {
        validate: [
          {
            guard: "bookingExistsOtherVenue",
            target: "bookingExistsOtherVenue",
          },
          { guard: "canPayForBooking", target: "requiresPayment" },
          {
            guard: "isBookingAlreadyPayedFor",
            target: "bookingAlreadyPayedFor",
          },
          { guard: "isTablesValid", target: "invalidTables" },
          { guard: "isOrderLinesValid", target: "invalidOrderLines" },
          { guard: "isTotalValid", target: "invalidTotal" },
          "valid",
        ],
      },
    },
    valid: {
      on: {
        edit: "editing",
        createBooking: [
          {
            target: "loggingIn",
            guard: "isNotLoggedIn",
          },
          {
            target: "updatingCustomerInfo",
            guard: "isIncompleteCustomer",
          },
          {
            target: "creatingBooking",
            guard: "isLoggedInAndHasInfo",
          },
          "validating",
        ],
      },
    },
    creatingBooking: {
      entry: emit(({ context }) => ({
        type: "creatingBooking",
        data: context,
      })),
      on: {
        setBookings: { actions: "setBookings" },
        setBooking: { actions: "setBooking" },
        error: { target: "validating" },
        pay: { guard: "isPayable", target: "creatingPayment" },
      },
    },
    creatingPayment: {
      entry: emit(({ context }) => ({
        type: "creatingPayment",
        data: context.booking!,
      })),
      on: {
        next: {
          target: "choosingPaymentMethod",
        },
        cancel: {
          target: "validating",
        },
      },
    },
    choosingPaymentMethod: {
      on: {
        next: { target: "paying" },
        cancel: { target: "validating" },
      },
    },
    paying: {
      entry: emit(({ context: { booking } }) => ({
        type: "paying",
        data: booking!, // This is guarded by "isPayable" which should assert it being defined
      })),
      on: {
        setBookings: { actions: "setBookings" },
        setBooking: { actions: "setBooking" },
        checkPayment: "checkingPayment",
        next: "done",
        cancel: "validating",
        error: "validating",
      },
    },
    checkingPayment: {
      on: {
        next: { target: "validating" },
      },
    },
    done: {
      after: {
        3000: { target: "validating" },
      },
    },
  },
});
