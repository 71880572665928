import React, {
  useState,
  useRef,
  HTMLAttributes,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { classNames } from "@greeter/util";
import css from "./Carousel.module.scss";
import { IonIcon, useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import { useIdle } from "@greeter/hooks";
import { chevronBack, chevronForward } from "ionicons/icons";

type CarouselProps = HTMLAttributes<HTMLDivElement> & {
  wrapperClassName?: string;
  infinite?: boolean;
  spacing?: "sm" | "md" | "lg";
  padding?: "sm" | "md" | "lg" | "same-as-spacing" | "none";
  fades?: boolean;
  centered?: boolean;
  // scroller?: (func: (index: number) => void) => void;
  index?: number;
  snap?: "center" | "start" | "none";
  startAt?: "start" | "center";
  style?: React.CSSProperties;
};

export const Carousel: React.FC<CarouselProps> = ({
  id,
  wrapperClassName,
  children,
  className,
  infinite,
  spacing = "sm",
  padding = "same-as-spacing",
  fades = false,
  centered = false,
  index,
  snap = "none",
  startAt = "start",
  style,
  ...props
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [infiniteArr, setInfiniteArr] = useState<Array<string>>(
    Array(50).fill("")
  );

  const idle = useIdle(carouselRef.current);

  const scrollToCenter = useCallback(() => {
    // We use timeout to compensate for some rendering issues.
    // This is the best fix atm.
    const id = setTimeout(() => {
      if (
        wrapperRef.current &&
        carouselRef.current &&
        startAt === "center" &&
        React.Children.count(children) > 0
      ) {
        const midElem =
          wrapperRef.current.children[
            Math.floor(wrapperRef.current.children.length / 2)
          ];
        midElem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }, 10_000);

    return () => clearTimeout(id);
  }, [children, startAt]);

  // Finds the middle slide and centers the slide in the carousel
  // TODO: This is inherently dependent on ionic atm. We should separate this in the future
  useIonViewDidEnter(() => {
    scrollToCenter();
  }, [scrollToCenter]);

  useEffect(() => {
    scrollToCenter();
  }, [scrollToCenter]);

  useEffect(() => {
    if (wrapperRef.current && carouselRef.current && index && idle) {
      const child = wrapperRef.current.children[index];
      if (child) {
        const childRect = child.getBoundingClientRect();
        const carouselRect = carouselRef.current.getBoundingClientRect();

        if (
          childRect.right < carouselRect.left ||
          childRect.left > carouselRect.right
        ) {
          // NOTE: this centers it to the screen
          carouselRef.current.scrollTo({
            left:
              carouselRef.current.scrollLeft +
              childRect.x -
              // NOTE: compensate for half the length of the carousel and half the length of the
              //       component that should be centered
              (carouselRef.current.getBoundingClientRect().width / 2 -
                childRect.width / 2),
          });
        }
      }
    }
  }, [children, index, idle]);

  const wrapperStyles = useMemo(
    () =>
      ({
        "--align": snap,
      } as React.CSSProperties),
    [snap]
  );

  const actualChildern = useMemo(() => {
    const wrappedChildren = React.Children.map(children, (c) => {
      return <div>{c}</div>;
    });
    return infinite ? infiniteArr.map(() => wrappedChildren) : wrappedChildren;
  }, [children, infinite, infiniteArr]);

  return (
    <div id={id} {...classNames(css.FadeContainer)} {...props}>
      <div
        ref={carouselRef}
        {...classNames(css.Carousel, css[`ScrollPadding__md`], className)}
        style={style}
      >
        <div
          ref={wrapperRef}
          {...classNames(css.CarouselWrapper, css[`Spacing___${spacing}`])}
          style={wrapperStyles}
        >
          {actualChildern}
        </div>
      </div>
      <div {...classNames(css[`FadeToRight___${fades}`])}></div>
      <div {...classNames(css[`FadeToLeft___${fades}`])}></div>
      <div className={css.PaginationControls}>
        <div
          {...classNames(css.Paginator)}
          onClick={() => {
            if (!carouselRef.current || !wrapperRef.current) return;

            const rect = carouselRef.current.getBoundingClientRect();
            const padding: CSSUnitValue | undefined = wrapperRef.current
              .computedStyleMap()
              .get("padding-left") as CSSUnitValue;
            carouselRef.current?.scrollBy((rect.width + padding.value) * -1, 0);
          }}
        >
          <IonIcon
            icon={chevronBack}
            style={{
              color: "var(--gm-color-alt-bg)",
              width: 20,
              height: 20,
              paddingRight: 2,
            }}
          />
        </div>

        <div
          {...classNames(css.Paginator)}
          onClick={() => {
            if (!carouselRef.current || !wrapperRef.current) return;

            const rect = carouselRef.current.getBoundingClientRect();
            const padding: CSSUnitValue | undefined = wrapperRef.current
              .computedStyleMap()
              .get("padding-left") as CSSUnitValue;

            carouselRef.current?.scrollBy(
              rect.width + (padding?.value ?? 0),
              0
            );
          }}
        >
          <IonIcon
            icon={chevronForward}
            style={{
              color: "var(--gm-color-alt-bg)",
              width: 20,
              height: 20,
              paddingLeft: 2,
            }}
          />
        </div>
      </div>
    </div>
  );
};
