import React from "react";
import { DaysliceConfig, Venue } from "@greeter/core";
import { GreeterEvent } from "@greeter/core";
import { TitledSection } from "@greeter-guest/components/Section/TitledSection";
import { UpcomingGreeterEventsCarousel } from "@greeter-guest/components/UpcomingGreeterEventsCarousel";
import { ActivitiesCarousel } from "@greeter-guest/components/ActivitiesCarousel";
import { Activity } from "@greeter/core";
import { Theme } from "@greeter/core";
import { Product } from "@greeter/core";
import {
  Carousel,
  VenueDescriptionCard,
  Container,
  FloatingBackButton,
  RoundedButton,
  LazyCoverImage,
  PageCover,
} from "@greeter/matter";
import { TablePackageCard } from "@greeter-guest/components/TablePackageCard";
import { isEmpty, Query } from "@greeter/util";

import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { Helmet } from "react-helmet";

import { CreateBookingV2Route, Tab } from "@greeter-guest/utility/Routes";

import css from "./VenuePage.module.scss";

export type VenuePageProps = {
  venue: Query<Venue>;
  greeterEvents?: GreeterEvent[];
  activities?: Activity[];
  themes?: Theme[];
  products?: Product[];
  venues?: Venue[];
  daysliceConfig: DaysliceConfig;
};

export function VenuePage({
  venue,
  greeterEvents = [],
  activities = [],
  themes = [],
  products = [],
  venues = [],
  daysliceConfig,
}: VenuePageProps) {
  const router = useIonRouter();

  return (
    <IonPage>
      {venue.type === "done" && (
        <Helmet>
          <title>Venue - {venue.data.name}</title>
          <meta name="id" content={venue.data.id} />
          <meta name="name" content={venue.data.name} />
          <meta name="og:title" content={venue.data.name} />
          <meta
            name="og:url"
            content={`https://app.greeter.dk/venue/${venue.data.id}`}
          />
          <meta name="og:description" content={venue.data.description} />
          <meta name="og:image" content={venue.data.coverUrl} />
        </Helmet>
      )}
      <FloatingBackButton />
      <IonContent>
        <Container>
          <div className={css.VenuePage}>
            {venue.type === "done" && (
              <>
                <PageCover>
                  <LazyCoverImage fade blur src={venue.data.coverUrl} />
                </PageCover>
                <VenueDescriptionCard
                  venue={venue.data}
                  themes={themes}
                  styles={{
                    content: {
                      ...(!isEmpty(products)
                        ? { paddingBottom: "1.25rem" }
                        : {}),
                    },
                  }}
                />
              </>
            )}
            {!isEmpty(products) && venue.type === "done" && (
              <RoundedButton
                active
                data-testid="book-table-btn"
                style={{
                  marginTop: "-2rem",
                  position: "relative",
                  background:
                    "linear-gradient(40deg, var(--gm-color-secondary-highlight), var(--gm-color-primary-highlight))",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "0.75rem 2rem",
                }}
                onClick={() => {
                  if (venue.type !== "done") return;

                  router.push(
                    new CreateBookingV2Route(router.routeInfo.tab as Tab).route(
                      { venueId: venue.data.id }
                    ),
                    "forward"
                  );
                }}
              >
                Book bord
              </RoundedButton>
            )}
            {!isEmpty(products) && venue.type === "done" && (
              <TitledSection padding={false} title="Bordpakker">
                <Carousel>
                  {Product.sortByPriceAndPriority(products).map((p, i) => (
                    <TablePackageCard
                      key={p.id}
                      venue={venue.data}
                      product={p}
                    />
                  ))}
                </Carousel>
              </TitledSection>
            )}
            {!isEmpty(greeterEvents) && (
              <TitledSection padding={false} title="Kommende events">
                <UpcomingGreeterEventsCarousel
                  greeterEvents={greeterEvents}
                  venues={venues}
                  daysliceConfig={daysliceConfig}
                />
              </TitledSection>
            )}
            {!isEmpty(activities) && (
              <TitledSection padding={false} title="Aktiviteter">
                <ActivitiesCarousel activities={activities} />
              </TitledSection>
            )}
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
}
