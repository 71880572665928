import { z } from "zod";
import { emptyProduct, ProductSchema } from "./Product";

export const VenueProductSchema = ProductSchema.extend({
  cost: z.number().default(0),
});
export type VenueProduct = z.infer<typeof VenueProductSchema>;

export const VenueProductsSchema = z.array(VenueProductSchema);
export type VenueProducts = z.infer<typeof VenueProductsSchema>;

export function emptyVenueProduct() {
  return { ...emptyProduct(), cost: 0 };
}
