/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
import { addSeconds, differenceInHours, hoursToSeconds } from "date-fns";
import { DateFactory } from "./DateFactory";
import { DatePeriod, LenientDatePeriod } from "./DatePeriod";
import { withTimeOfDay } from "./DateUtil";
import { TimeOfDay } from "./TimeOfDay";
import { assert } from "@greeter/assert";
import { z } from "zod";

export const TimeOfDayPeriodSchema = z.object({
  from: z.string().transform(TimeOfDay.parse),
  to: z.string().transform(TimeOfDay.parse),
});

export type TimeOfDayPeriod = {
  from: TimeOfDay;
  to: TimeOfDay;
};

export module TimeOfDayPeriod {
  export function fromString(from: string, to: string): TimeOfDayPeriod {
    return {
      from: TimeOfDay.parse(from),
      to: TimeOfDay.parse(to),
    };
  }

  export function toDatePeriod(
    period: TimeOfDayPeriod,
    date: Date = DateFactory.create()
  ): DatePeriod {
    const start = withTimeOfDay(date, period.from);
    const end = period.to.isEarlierThan(period.from)
      ? addSeconds(withTimeOfDay(date, period.to), hoursToSeconds(24))
      : withTimeOfDay(date, period.to);

    return new DatePeriod({ from: start, to: end });
  }

  /**
   * @throws {AssertionError} if the {@link DatePeriod} difference between from and to is larger than 24 hours
   */
  export function fromDatePeriod(period: LenientDatePeriod): TimeOfDayPeriod {
    assert(
      differenceInHours(period.to, period.from) <= 24,
      "expected difference between period.from and period.to to be LESS than 24 hours. This is probably wrong."
    );

    return {
      from: TimeOfDay.fromDate(period.from),
      to: TimeOfDay.fromDate(period.to),
    };
  }
}
