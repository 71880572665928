import { Tab, whichTab } from "@greeter-guest/utility/Routes";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

/**
 * useIonRouter is profoundly inconsistent. So I build my own tab detecter.
 * I mean, how hard can it be seriously.
 *
 * Specifically when redirecting back from mobilepay payment and landing on create-booking page
 * for some reason ionic is not picking up that we are in the greeter-events tab and defaults
 * to the venues tab. Hopefully this is obsolete in the future.
 *
 * Defaults to the venues tab.
 */
export function useDetectTab(): Tab | undefined {
  const location = useLocation();

  return useMemo(() => {
    return whichTab(location.pathname);
  }, [location]);
}
