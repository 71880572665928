import React, { useMemo } from "react";
import { doNothing } from "@greeter/util";
import {
  OnCityChange,
  OnThemeChange,
  VenueOverviewPage,
} from "./VenueOverviewPage";
import { useDefaultGuestApi } from "@greeter-guest/api/api";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { flatten } from "lodash";
import { useCitiesQuery } from "@greeter/guest-api-hooks";
import { Venue } from "@greeter/core";

export type VenueOverviewPageApiHandlerProps = {
  themeId?: string;
  activityId?: string;
  onThemeChange?: OnThemeChange;
  country: string;
  onCityChange?: OnCityChange;
  city: string;
};

export const VenueOverviewPageApiHandler: React.FC<
  VenueOverviewPageApiHandlerProps
> = ({
  activityId,
  themeId = "all",
  onThemeChange = doNothing,
  onCityChange = doNothing,
  country,
  city,
}) => {
  const api = useDefaultGuestApi();
  const queryClient = useQueryClient();

  const cities = useCitiesQuery(api, country);

  const venuesQuery = useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ["venues", city, activityId, themeId],
    queryFn: async ({ pageParam = 0 }) => {
      // TODO: Change to dynamic location
      if (themeId === "all") {
        const venues = await api.fetchVenues({
          page: pageParam,
          city: city,
          country: country,
        });
        console.debug("[venuesQuery]", themeId, venues);
        return venues;
      } else if (themeId || activityId)
        return await api.fetchVenues({
          page: pageParam,
          city: city,
          country: country,
          activityId,
          themeId,
        });
      else return [];
    },
    getNextPageParam: (lastPage, pages) => pages.length,
  });

  console.debug("venuesquery", venuesQuery.data);

  const activityQuery = useQuery({
    queryKey: ["activities", activityId],
    queryFn: async () => {
      if (activityId) {
        const activities = await api.fetchActivities();
        activities.forEach((a) => {
          queryClient.setQueryData(["activity", a.id], a);
        });
        return activities.find((a) => a.id === activityId);
      }
    },
  });
  const themesQuery = useQuery({
    queryKey: ["themes", activityQuery.data?.id],
    queryFn: async () =>
      await api.fetchThemes({ activityId: activityQuery.data?.id }),
  });

  const venues = useMemo(
    () => flatten(venuesQuery.data?.pages),
    [venuesQuery.data]
  );

  return (
    <VenueOverviewPage
      activity={activityQuery.data}
      themes={themesQuery.data ?? []}
      venues={venues}
      selectedThemeId={themeId}
      onThemeChange={onThemeChange}
      cities={(cities.data as string[]) ?? []}
      city={city}
      onCityChange={onCityChange}
    />
  );
};
