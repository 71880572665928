import {
  CreateBookingRequest,
  CreatePaymentArgs,
  CreateTableServiceOrderRequest,
  FetchThemesRequest,
  IGuestApi,
  PagedByVenueRequest,
  UpdateCustomerRequest,
  UpdateUserArgs,
  VenueRequest,
} from "./IGuestApi";
import { UrlBuilder } from "@greeter/url";
import {
  GreeterEvent,
  Venue,
  Activity,
  VenueActivity,
  NewsItem,
  DaysliceConfig,
  Theme,
  Product,
  Order,
  FloorPlan,
  MusicGenre,
  StripeEphemeralKey,
  TableId,
  Payment,
  Customer,
  CustomerSchema,
  BookingSettings,
  LockedTable,
  Menu,
  TableServiceOrder,
  TableServiceSettings,
  SpecialOpeningHours,
  SpecialPricePeriod,
  SpecialPricePeriodSchema,
  MenuSchema,
  Bundle,
  BundlesSchema,
} from "@greeter/core";
import { ApiVersions, BaseProxy } from "./ProxyBase";

import {
  FloorPlanDeserializer,
  GreeterEventDeserializer,
  IDeserializer,
  OrderDeserializer,
  ProductDeserializer,
  ThemeDeserializer,
  VenueDeserializer,
  deserializeBooking,
} from "./deserializers";
import { PagedLocationRequest, PageRequest } from "./Requests";
import { uniq } from "lodash";

import { IAuthentication } from "@greeter/auth";
import { Config } from "@greeter/config";
import { DatePeriod, TimeOfDay, toISODateString } from "@greeter/date";
import { ManySpecialOpeningHoursDeserializer } from "./deserializers/SpecialOpeningHoursDeserializer";

export class GuestApi extends BaseProxy implements IGuestApi {
  url: URL;

  constructor(
    config: Config,
    authentication: IAuthentication,
    private orderDeserializer: IDeserializer<Order> = new OrderDeserializer(),
    private floorPlanDeserializer: IDeserializer<FloorPlan> = new FloorPlanDeserializer(),
    private venueDeserializer: IDeserializer<Venue> = new VenueDeserializer(),
    private productDeserializer: IDeserializer<Product> = new ProductDeserializer(),
    private greeterEventDeserializer: IDeserializer<GreeterEvent> = new GreeterEventDeserializer(),
    private themeDeserializer: IDeserializer<Theme> = new ThemeDeserializer()
  ) {
    super(config, authentication);
    this.url = new URL(`${this.getBaseUrl()}/api`);
    this.apiVersion = ApiVersions.V2;
  }

  async fetchBundlesByVenue(venueId: string): Promise<Array<Bundle>> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("bundles")
      .addQuery("venueId", venueId)
      .build();

    const result = await this.get(url);

    return BundlesSchema.parse(result);
  }

  async fetchCategories() {}

  async fetchCities(country: string) {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath("cities")
      .addPath(country)
      .build();

    const result = await this.get(url);

    return result as string[];
  }

  async createTableServiceOrder({
    venueId,
    ...request
  }: CreateTableServiceOrderRequest) {
    const url = new UrlBuilder(this.getUrl())
      .addPath("tableServiceOrders")
      .addPath(venueId)
      .build();

    const result = await this.postWithAuth(
      url,
      JSON.stringify({ ...request.tableId, orderLines: request.orderLines })
    );

    return result as TableServiceOrder;
  }

  async fetchTableServiceOrderPdf(id: string) {
    const url = new UrlBuilder(this.getUrl())
      .addPath("tableServiceOrders")
      .addPath(id)
      .addPath("pdf")
      .build();

    const result = await this.getFileWithAuth(url, (init) => ({
      headers: { ...init.headers, "Content-Type": "application/pdf" },
    }));

    return result;
  }

  async fetchTableServiceOrder(id: string) {
    const url = new UrlBuilder(this.getUrl())
      .addPath("tableServiceOrders")
      .addPath(id)
      .build();

    const result = await this.getWithAuth(url);

    return result as TableServiceOrder;
  }

  async fetchTableServiceOrders(request: PageRequest) {
    const url = new UrlBuilder(this.getUrl())
      .addQuery("page", request.page)
      .addQuery("pageSize", request.pageSize)
      .build();

    const result = await this.getWithAuth(url);

    return result as TableServiceOrder[];
  }

  async fetchTableServiceSettings(venueId: string) {
    const url = new UrlBuilder(this.getUrl())
      .addPath("tableServiceSettings")
      .addQueries({ venueId })
      .build();

    const result = await this.get(url);

    return result as TableServiceSettings;
  }

  async fetchMenu(venueId: string): Promise<Menu> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("menus")
      .addQuery("venueId", venueId)
      .build();

    const result = await this.get(url);

    return MenuSchema.parse(result);
  }

  async fetchBookingSettings(venueId: string): Promise<BookingSettings> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("bookingSettings")
      .addPath("byVenue")
      .addPath(venueId)
      .build();

    const result = await this.get(url);

    return { buffer: TimeOfDay.parse(result.buffer) } as BookingSettings;
  }

  async deleteUser() {
    const url = new UrlBuilder(this.getUrl()).addPath("user").build();

    await this.delete(url);
  }

  async fetchCustomer() {
    const url = new UrlBuilder(this.getUrl()).addPath("customer").build();

    return CustomerSchema.parse(await this.getWithAuth(url));
  }

  async subscribeToNotifications(registrationsId: string): Promise<void> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("pushNotificationSubscriptions")
      .addPath(registrationsId)
      .build();

    const r = await this.authPost(url);

    if (!r.ok) {
      throw new Error("Failed to subscribe to push notifications for customer");
    }
  }

  async createPayment({
    orderId,
    redirectUrl,
  }: CreatePaymentArgs): Promise<Payment> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("orders")
      .addPath(orderId)
      .addPath("payment")
      .addQuery("redirectUrl", redirectUrl)
      .build();

    const response = await this.postWithAuth(url);

    return response as Payment;
  }

  async createBooking(request: CreateBookingRequest) {
    const url = new UrlBuilder(this.getUrl()).addPath("bookings").build();

    const response = await this.postWithAuth(url, JSON.stringify(request));

    return deserializeBooking(response);
  }

  async fetchBookings() {
    const url = new UrlBuilder(this.getUrl()).addPath("bookings").build();

    const response = await this.getWithAuth(url);

    const deserialized = response.map(deserializeBooking);

    return deserialized;
  }

  async fetchBooking(id: string) {
    const url = new UrlBuilder(this.getUrl())
      .addPath("bookings")
      .addPath(id)
      .build();

    const response = await this.getWithAuth(url);
    const booking = deserializeBooking(response);

    return booking;
  }

  async fetchStripeEphemeralKey(): Promise<StripeEphemeralKey> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("stripe")
      .addPath("EphemeralKey")
      .build();

    const response = await this.getWithAuth(url);

    return response as StripeEphemeralKey;
  }

  protected getUrl() {
    return this.url;
  }

  async fetchFloorPlan(venueId: string): Promise<FloorPlan> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath(venueId)
      .addPath("floorplan")
      .build();

    const response = await this.getWithAuth(url);

    return this.floorPlanDeserializer.deserialize(response);
  }

  async fetchBookedTables(venueId: string, forDate: Date): Promise<TableId[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath(venueId)
      .addPath("bookedTables")
      .addPath(toISODateString(forDate))
      .build();

    const response = await this.getWithAuth(url);

    return response.map((r: any) => ({
      area: r.area,
      tableNumber: r.tableNumber,
    }));
  }

  async fetchLockedTables(
    venueId: string,
    forDate: Date
  ): Promise<LockedTable[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath(venueId)
      .addPath("lockedTables")
      .addPath(toISODateString(forDate))
      .build();

    const response = await this.getWithAuth(url);

    return response as LockedTable[];
  }

  /* ========================= type: Order ========================= */
  async fetchOrder(orderId: string): Promise<Order> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("orders")
      .addPath(orderId)
      .build();
    const result: any = await this.getWithAuth(url);

    return this.orderDeserializer.deserialize(result);
  }

  async fetchOrderRejectionMessage(orderId: string): Promise<string> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("orders")
      .addPath(orderId)
      .addPath("rejectionMessage")
      .build();

    return await this.fetchSingleRejection(url);
  }

  async fetchVenueByBooking(bookingId: string): Promise<Venue> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath("byBooking")
      .addPath(bookingId)
      .build();

    return await this.fetchSingleVenue(url);
  }

  async fetchVenueByOrder(orderId: string): Promise<Venue> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath("byOrder")
      .addPath(orderId)
      .build();

    return await this.fetchSingleVenue(url);
  }

  async fetchOrders(): Promise<Order[]> {
    const url = new UrlBuilder(this.getUrl()).addPath("orders").build();
    const result: any = await this.getWithAuth(url);
    return await Promise.all(this.orderDeserializer.deserializeAll(result));
  }

  /* ========================= type: User ========================= */
  async fetchIsUserComplete(): Promise<boolean> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("user")
      .addPath("isComplete")
      .build();

    const result = (await this.getWithAuth(url)) as any;
    return result.completed;
  }

  async updateCustomer(args: UpdateCustomerRequest): Promise<Customer> {
    const url = new UrlBuilder(this.getUrl()).addPath("customer").build();

    const body = JSON.stringify(args);
    const response = await this.postWithAuth(url, body);
    const parsed = CustomerSchema.parse(response);
    return parsed;
  }

  async updateUser(args: UpdateUserArgs): Promise<void> {
    const url = new UrlBuilder(this.getUrl()).addPath("user").build();

    const body = JSON.stringify(args);
    await this.patch(url, body);
  }

  async sendVerificationLink(): Promise<void> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("user")
      .addPath("resendVerificationLink")
      .build();

    await this.authPost(url);
  }

  /* ========================= type: GreeterEvent ========================= */

  async fetchPreviousGreeterEventsForVenue({
    venueId,
    page,
    pageSize,
  }: PagedByVenueRequest): Promise<GreeterEvent[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("greeterEvents")
      .addQuery("venueId", venueId)
      .addQuery("page", page)
      .addQuery("direction", "previous")
      .build();

    return await this.fetchMultipleEvents(url);
  }

  async fetchPreviousGreeterEvents({
    city,
    country,
    page,
    pageSize,
  }: PagedLocationRequest): Promise<GreeterEvent[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("greeterEvents")
      .addQuery("page", page)
      .addQuery("pageSize", pageSize)
      .addQuery("city", city)
      .addQuery("country", country)
      .addQuery("direction", "previous")
      .build();

    return await this.fetchMultipleEvents(url);
  }

  async fetchUpcomingGreeterEventsForVenue({
    venueId,
    page,
    pageSize,
  }: PagedByVenueRequest): Promise<GreeterEvent[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("greeterEvents")
      .addQuery("venueId", venueId)
      .addQuery("page", page)
      .addQuery("pageSize", pageSize)
      .addQuery("direction", "upcoming")
      .build();

    return await this.fetchMultipleEvents(url);
  }

  async fetchUpcomingGreeterEvents({
    city,
    country,
    page,
    pageSize,
  }: PagedLocationRequest): Promise<GreeterEvent[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("greeterEvents")
      .addQuery("direction", "upcoming")
      .addQuery("page", page)
      .addQuery("pageSize", pageSize)
      .addQuery("city", city)
      .addQuery("country", country)
      .build();

    return await this.fetchMultipleEvents(url);
  }

  async fetchGreeterEvent(id: string): Promise<GreeterEvent> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("greeterEvents")
      .addPath(id)
      .build();

    return await this.fetchSingleEvent(url);
  }

  /* ========================= type: Venue ========================= */
  async fetchSpecialOpeningHours(
    venueId: string
  ): Promise<SpecialOpeningHours[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath(venueId)
      .addPath("specialOpeningHours")
      .build();

    const r = await this.get(url);
    const parsed = ManySpecialOpeningHoursDeserializer.parse(r);

    const mapped = Array(parsed.length);
    for (let i = 0; i < parsed.length; i++) {
      const oh = parsed[i];
      mapped[i] = {
        id: oh.id,
        period: new DatePeriod({ from: oh.period.from, to: oh.period.to }),
      };
    }

    return mapped;
  }

  async fetchVenue(id: string): Promise<Venue> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath(id)
      .build();

    return await this.fetchSingleVenue(url);
  }

  async fetchVenues({
    ids,
    activityId,
    themeId,
    page,
    pageSize,
    city,
    country,
  }: VenueRequest) {
    const builder = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addQuery("ids", uniq(ids)?.join(","))
      .addQuery("activityId", activityId)
      .addQuery("themeId", themeId)
      .addQuery("page", page)
      .addQuery("pageSize", pageSize)
      .addQuery("city", city)
      .addQuery("country", country);

    const url = builder.build();

    return await this.fetchMultipleVenues(url);
  }

  async fetchVenueByEvent(id: string): Promise<Venue> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath("byEvent")
      .addPath(id)
      .build();

    return await this.fetchSingleVenue(url);
  }

  /* ========================= type: News ========================= */

  async fetchNews(): Promise<NewsItem[]> {
    const url = new UrlBuilder(this.getUrl()).addPath("news").build();
    return await this.fetchMultipleNews(url);
  }

  async fetchDaysliceConfig(): Promise<DaysliceConfig> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("keyValues")
      .addPath("dayslices")
      .build();
    return await this.get(url).then((json) => json as DaysliceConfig);
  }

  /* ===== type: Activity ===== */

  async fetchActivities(): Promise<Activity[]> {
    const url = new UrlBuilder(this.getUrl()).addPath("activities").build();

    return await this.fetchMultipleActivities(url);
  }

  async fetchVenueActivities(venueId: string): Promise<VenueActivity[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("venues")
      .addPath(venueId)
      .addPath("activities")
      .build();

    return await this.fetchMultipleVenueActivities(url);
  }

  /* ====== type: Theme ====== */
  async fetchThemes(request?: FetchThemesRequest): Promise<Theme[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("themes")
      .addQuery("activityId", request?.activityId)
      .build();
    return await this.fetchMultipleThemes(url);
  }

  async fetchThemesByVenue(venueId: string) {
    const url = new UrlBuilder(this.getUrl())
      .addPath("themes")
      .addQuery("venueId", venueId)
      .build();

    return await this.fetchMultipleThemes(url);
  }

  /* ====== type: Product ===== */
  async fetchProductsByVenue(venueId: string): Promise<Product[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("products")
      .addQuery("venueId", venueId)
      .build();

    return await this.fetchMultipleProducts(url);
  }

  async fetchSpecialPricePeriods(
    productIds: Array<string>
  ): Promise<Array<SpecialPricePeriod>> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("specialPricePeriods")
      .addQuery("productIds", productIds)
      .build();

    const json = await this.getWithAuth(url);

    if (!Array.isArray(json)) {
      throw new Error("Wrong type for SpecialPricePeriods, we need an array");
    }

    const parsed = json.map((j) => SpecialPricePeriodSchema.parse(j));

    return parsed;
  }

  async fetchMusicGenres(request: PageRequest): Promise<MusicGenre[]> {
    const url = new UrlBuilder(this.getUrl())
      .addPath("musicgenres")
      .addQuery("page", request?.page)
      .addQuery("pageSize", request?.pageSize)
      .build();

    return (await this.get(url)) as MusicGenre[];
  }

  /* ===== Util stuff ===== */
  private async fetchSingleEvent(url: string): Promise<GreeterEvent> {
    return this.greeterEventDeserializer.deserialize(await this.get(url));
  }

  private async fetchMultipleEvents(url: string): Promise<GreeterEvent[]> {
    return this.greeterEventDeserializer.deserializeAll(await this.get(url));
  }

  private async fetchSingleVenue(url: string): Promise<Venue> {
    return this.venueDeserializer.deserialize(await this.get(url));
  }

  private async fetchSingleRejection(url: string): Promise<string> {
    return this.createStringFromJson(await this.get(url));
  }

  private async fetchMultipleVenues(url: string): Promise<Venue[]> {
    return this.venueDeserializer.deserializeAll(await this.get(url));
  }

  private async fetchMultipleActivities(url: string): Promise<Activity[]> {
    return this.createActivitiesFromJsonList(await this.get(url));
  }

  private async fetchMultipleVenueActivities(
    url: string
  ): Promise<VenueActivity[]> {
    return this.createVenueActivitiesFromJsonList(await this.get(url));
  }

  private async fetchMultipleNews(url: string): Promise<NewsItem[]> {
    return this.createNewsItemsFromJson(await this.get(url));
  }

  private async fetchMultipleThemes(url: string): Promise<Theme[]> {
    return this.themeDeserializer.deserializeAll(await this.get(url));
  }

  private async fetchMultipleProducts(url: string): Promise<Product[]> {
    return Product.sortByPriceAndPriority(
      this.productDeserializer.deserializeAll(await this.get(url))
    );
  }

  private createProductsFromJson(json: any): Product[] {
    return this.productDeserializer.deserializeAll(json);
  }

  private createNewsItemsFromJson(json: any): NewsItem[] {
    const newsItems = json.map((newsItem: any) => {
      return {
        title: newsItem.title,
        body: newsItem.body,
        imageUrl: newsItem.imageUrl,
        link: {
          type: newsItem.linkType,
          data: newsItem.linkData,
        },
      };
    });
    return newsItems;
  }

  private createConfigFromJson<T>(config: any, json: any): T {
    return Object.assign(config, json) as T;
  }

  private createStringFromJson(json: any): string {
    return json.message;
  }

  private createActivityFromJson(json: any): Activity {
    return { id: json.id, name: json.name, coverUrl: json.coverUrl };
  }

  private createVenueActivityFromJson(json: any): VenueActivity {
    //Is this to overcomplicate? Pro is that if Activity changes, we dont have to change this stuff.
    //But on the other hand, we still need to change the class VenueActivity, to adapt to Activity's change
    const va = this.createActivityFromJson(json) as VenueActivity;
    va.description = json.description;
    return va;
  }

  private createActivitiesFromJsonList(json: any): Activity[] {
    const out = json.map((el: unknown) => this.createActivityFromJson(el));
    return out;
  }

  private createVenueActivitiesFromJsonList(json: any): VenueActivity[] {
    const out = json.map((el: unknown) => this.createVenueActivityFromJson(el));
    return out;
  }
}
