import React, { useMemo } from "react";

import Routes, {
  Tab,
  VenueRouteV2,
  whichTab,
} from "@greeter-guest/utility/Routes";
import { ImageAsset, Venue } from "@greeter/core";

import { BigContentCard } from "@greeter-guest/components/BigContentCard";
import { useHistory, useLocation } from "react-router-dom";
import { isAfter } from "date-fns";
import { toPrettyDateString } from "@greeter-guest/utility/DateUtil";
import { DateFactory, dateFormatter } from "@greeter/date";
import { WeeklyOpeningHours } from "@greeter/core";
import { partial } from "lodash";
import { match } from "ts-pattern";
import { classNames } from "@greeter/util";
import { BaseProps, GreenLabel, GreyLabel, YellowLabel } from "@greeter/matter";

import "./VenueCard.scss";
import styles from "./VenueCard.module.scss";
import { useIonRouter } from "@ionic/react";

type VenueCardProps = BaseProps & {
  venue: Venue;
};

export const VenueLabel: React.FC<VenueCardProps> = ({ venue }) => {
  const { openingHours, launchDate } = venue;

  const now = DateFactory.create();

  const isLaunchDateInTheFuture = isAfter(launchDate, now);

  if (isLaunchDateInTheFuture) {
    return <GreenLabel text={`Åbner d. ${toPrettyDateString(launchDate)}`} />;
  }

  const openStatus = WeeklyOpeningHours.getOpenStatus(openingHours, now);

  return match(openStatus)
    .with({ when: "now" }, () => <GreenLabel text="Åbent" />)
    .with({ when: "today" }, ({ at }) => (
      <YellowLabel text={`Åbner kl. ${at.toString()}`} />
    ))
    .with({ when: "tomorrow" }, ({ at }) => (
      <YellowLabel text={`Åbner i morgen ${at.toString()}`} />
    ))
    .with({ when: "future" }, ({ at }) => (
      <YellowLabel text={`Åbner d. ${dateFormatter.format(at)}`} />
    ))
    .otherwise(() => <GreyLabel text={"Lukket"} />);
};

export function VenueCard({ venue, style, className }: VenueCardProps) {
  const history = useIonRouter();
  const address = useMemo(
    () => `${venue.address.street}, ${venue.address.city}`,
    [venue.address.city, venue.address.street]
  );
  const label = useMemo(() => <VenueLabel venue={venue} />, [venue]);
  const location = useLocation();

  return (
    <BigContentCard
      {...classNames(styles.BigContentCard, className)}
      onClick={() => {
        const tab = whichTab(location.pathname);
        if (tab) {
          history.push(
            new VenueRouteV2(tab).route({
              venueId: venue.id,
            })
          );
        }
      }}
      title={venue.name}
      cover={ImageAsset.findUriWithSizeOrDefault(
        venue.coverAsset,
        "16x9-w1024"
      )}
      venue={venue}
      description={address}
      label={label}
      style={style}
    />
  );
}
