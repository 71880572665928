import { queries, paths, toSearchParams } from "@greeter/url";

export type Intent = "check-payment";

function tabTemplate(s?: Tab): string {
  if (!s) return "";
  return `/:tab(${s})`;
}

export interface ITemplate {
  template(): string;
}

export interface IRoute<TParams> {
  route(params: TParams): string;
}

abstract class Route<T> implements ITemplate, IRoute<T> {
  route(query: T): string {
    return this.getBase();
  }

  absoluteRoute(query: T): string {
    if (window === undefined) return this.route(query);

    return window.location.origin + this.route(query);
  }

  template() {
    return this.getBase();
  }

  abstract getBase(): string;
}

function getLocationQueries(q: URLSearchParams) {
  const city = q.get("city");
  const country = q.get("country");

  const s = new URLSearchParams(window.location.search);
  const passedOn = {
    city: s.get("city"),
    country: s.get("country"),
  };

  if (!city && passedOn.city) {
    q.set("city", passedOn.city);
  }
  if (!country && passedOn.country) {
    q.set("country", passedOn.country);
  }

  return q;
}

export type GreeterEventParams = {
  greeterEventId: string;
};
class GreeterEventRoute extends Route<GreeterEventParams> {
  getBase(): string {
    return "/greeter-event";
  }

  override route(query: GreeterEventParams): string {
    return `${this.getBase()}/${query.greeterEventId}`;
  }

  override template(): string {
    return `${this.getBase()}/:greeterEventId`;
  }
}

export type GreeterEventV2Params = {
  greeterEventId: string;
};
export class GreeterEventV2Route extends Route<GreeterEventParams> {
  constructor(private tab?: Tab) {
    super();
  }

  getBase(): string {
    if (this.tab === "greeter-events") {
      return "/greeter-events";
    }

    return `/${paths(this.tab, "greeter-events")}`;
  }

  override route(query: GreeterEventParams): string {
    return `${this.getBase()}/${query.greeterEventId}`;
  }

  override template(): string {
    return `${this.getBase()}/:greeterEventId`;
  }
}

class GreeterEventsRoute extends Route<void> {
  getBase(): string {
    return "/greeter-events";
  }

  override template() {
    return `${tabTemplate(Tab.Events)}`;
  }
}

type VenuesRouteParams = {
  queries?: {
    activityId?: string;
    themeId?: string;
  };
};
class VenuesRoute extends Route<VenuesRouteParams> {
  getBase(): string {
    return `/${Tab.Venues}`;
  }

  override template() {
    const t = `${tabTemplate(Tab.Venues)}`;
    console.debug(`${VenuesRoute.name}.${this.template.name}(): ${t}`);
    return t;
  }

  override route(params: VenuesRouteParams) {
    const q = getLocationQueries(toSearchParams(params));
    const route = `${this.getBase()}${
      q.toString() !== "" ? "?" + q.toString() : ""
    }`;
    console.debug(
      `${VenuesRoute.name}.${this.route.name}(params: ${JSON.stringify(
        params,
        null,
        2
      )}) -> ${route}`
    );
    return route;
  }
}

type VenueRouteParams = {
  venueId: string;
};
class VenueRoute extends Route<VenueRouteParams> {
  getBase(): string {
    return "/venue";
  }

  override route(params: VenueRouteParams) {
    return `${this.getBase()}/${params.venueId}`;
  }

  override template() {
    const template = `${this.getBase()}/:venueId?`;

    return template;
  }
}

type VenueRouteV2Params = {
  venueId: string;
};
export class VenueRouteV2 extends Route<VenueRouteV2Params> {
  constructor(private tab: Tab) {
    super();
  }

  getBase(): string {
    return `/`;
  }

  override route(params: VenueRouteParams) {
    const r = `/${paths(this.tab, params.venueId)}`;
    console.debug(
      `${VenueRouteV2.name}.${this.route.name}(params: ${JSON.stringify(
        params,
        null,
        2
      )}) -> ${r}`
    );
    return r;
  }

  override template() {
    const template = `${tabTemplate(this.tab)}/:venueId`;
    console.debug(
      `${VenueRouteV2.name}.${this.template.name}() -> ${template}`
    );
    return template;
  }
}

export type HomeParams = {
  city?: string;
  country?: string;
};

export class HomeRoute extends Route<HomeParams | undefined> {
  constructor(private tab?: Tab) {
    super();
  }

  getBase(): string {
    if (this.tab === Tab.Events) {
      return "/greeter-events";
    } else if (this.tab === Tab.Profile) {
      return "/profile";
    } else {
      return "/venues";
    }
  }

  override route(params?: HomeParams) {
    const q = getLocationQueries(toSearchParams(params));
    return `${this.getBase()}${q.toString()}`;
  }
}

class ProfileRoute extends Route<void> {
  getBase(): string {
    return "/profile";
  }
}

export type CreateBookingParams = {
  venueId: string;
  productId?: string;
  arrivalDate?: Date;
  bookingId?: string;
  intent?: Intent;
  paymentToken?: string;
};
class CreateBookingRoute extends Route<CreateBookingParams> {
  getBase(): string {
    return "/create-booking";
  }

  override route(params: CreateBookingParams) {
    return `/create-booking/${params.venueId}${queries({
      bookingId: params.bookingId,
      productId: params.productId,
      arrivalDate: params.arrivalDate?.toISOString(),
      intent: params.intent,
      paymentToken: params.paymentToken,
    })}`;
  }

  override template() {
    return `${this.getBase()}/:venueId`;
  }
}

export type CreateBookingV2NewFlowRouteProps = {
  venueId: string;
};
export class CreateBookingV2NewFlowRoute extends Route<void> {
  constructor(private tab: Tab) {
    super();
  }

  getBase(): string {
    return "/create-booking-v2-beta";
  }

  override route() {
    const r = `/${paths(this.tab, this.getBase())}`;
    console.debug(
      `${CreateBookingV2NewFlowRoute.name}.${this.route.name}(): ${r}`
    );
    return r;
  }

  override template() {
    const t = `/${paths(tabTemplate(this.tab), this.getBase())}`;
    console.debug(
      `${CreateBookingV2NewFlowRoute.name}.${this.template.name}(): ${t}`
    );
    return t;
  }
}

export type CreateBookingV2Params = {
  venueId: string;
  productId?: string;
  arrivalDate?: Date;
  bookingId?: string;
  intent?: Intent;
  paymentToken?: string;
};
export class CreateBookingV2Route extends Route<CreateBookingV2Params> {
  constructor(private tab?: Tab) {
    super();
  }

  getBase(): string {
    throw new Error("Not useful for this Route.");
  }

  override route(params: CreateBookingParams) {
    const queryString = queries({
      bookingId: params.bookingId,
      productId: params.productId,
      arrivalDate: params.arrivalDate?.toISOString(),
      intent: params.intent,
      paymentToken: params.paymentToken,
    });

    if (this.tab === "venues") {
      return `/${paths(
        this.tab,
        params.venueId,
        "create-booking"
      )}${queryString}`;
    }

    return `/${paths(
      this.tab,
      "create-booking",
      params.venueId
    )}${queryString}`;
  }

  override template() {
    if (this.tab === "venues") {
      return `/${paths(tabTemplate(this.tab), ":venueId", "create-booking")}`;
    }
    return `/${paths(tabTemplate(this.tab), "create-booking", ":venueId")}`;
  }
}

export type CreateBookingV3Params = {
  venueId: string;
  productId?: string;
  arrivalDate?: Date;
  bookingId?: string;
  intent?: Intent;
  paymentToken?: string;
};
export class CreateBookingV3Route extends Route<CreateBookingV3Params> {
  constructor(private tab?: Tab) {
    super();
  }

  getBase(): string {
    throw new Error("Not useful for this Route.");
  }

  override route(params: CreateBookingParams) {
    const queryString = queries({
      bookingId: params.bookingId,
      productId: params.productId,
      arrivalDate: params.arrivalDate?.toISOString(),
      intent: params.intent,
      paymentToken: params.paymentToken,
    });

    if (this.tab === "venues") {
      return `/${paths(
        this.tab,
        params.venueId,
        "create-booking-v2"
      )}${queryString}`;
    }

    return `/${paths(
      this.tab,
      "create-booking-v2",
      params.venueId
    )}${queryString}`;
  }

  override template() {
    if (this.tab === "venues") {
      return `/${paths(
        tabTemplate(this.tab),
        ":venueId",
        "create-booking-v2"
      )}`;
    }
    return `/${paths(tabTemplate(this.tab), "create-booking-v2", ":venueId")}`;
  }
}

export type BookingSummaryParams = {
  bookingId: string;
};
export class BookingSummaryRoute extends Route<BookingSummaryParams> {
  constructor(private tab?: Tab) {
    super();
  }

  getBase(): string {
    return `/summary`;
  }

  override template() {
    // TODO: We could probably easily extend Route to use Queries as well as Params
    //       where params would be converted to :path elements and queries tostringed query in route.
    return `/${paths(tabTemplate(this.tab), this.getBase(), ":bookingId")}`;
  }

  override route(params: BookingSummaryParams) {
    return `/${paths(this.tab, this.getBase(), params.bookingId)}`;
  }
}

export type LoginRouteParams = {
  redirect?: string;
};

class LoginRoute extends Route<LoginRouteParams> {
  getBase(): string {
    return "/login";
  }

  override route(params?: LoginRouteParams) {
    if (!params || !params.redirect) return this.getBase();

    return `${this.getBase()}${queries(params)}`;
  }
}

class PrivacyRoute extends Route<void> {
  getBase(): string {
    return "/privacy";
  }
}

type OutboundParams = {
  url: string;
};
class OutboundRoute extends Route<void> {
  getBase(): string {
    return "/outbound";
  }
}

class UpdateUserRoute extends Route<void> {
  constructor(private tab?: Tab) {
    super();
  }

  getBase() {
    return `/${paths(this.tab, "update-user")}`;
  }
}

type DeleteUserParams = {
  redirectUrl?: string;
};

class DeleteUserRoute extends Route<DeleteUserParams> {
  constructor(private tab?: Tab) {
    super();
  }

  getBase() {
    return `/delete-user`;
  }

  override() {
    return `${paths(tabTemplate(this.tab))}/delete-user`;
  }

  override route(params: DeleteUserParams) {
    if (params.redirectUrl) {
      return `${paths(this.tab, this.getBase())}${queries({
        redirectUrl: params.redirectUrl,
      })}`;
    }

    return this.getBase();
  }
}

type MenuRouteParams = {
  venueId: string;
  tableNumber: number;
  area: string;
};
class MenuRoute extends Route<MenuRouteParams> {
  getBase() {
    return "/menu";
  }

  override route(params: MenuRouteParams) {
    return `${this.getBase()}${queries(params)}`;
  }
}

type CartRouteParams = {
  id?: string;
  venueId: string;
  tableNumber: number;
  area: string;
  intent?: Intent;
};

class CartRoute extends Route<CartRouteParams> {
  getBase() {
    return "/cart";
  }

  override route(params: CartRouteParams) {
    return `${this.getBase()}${queries(params)}`;
  }
}

type ReceiptRouteParams = {
  tableServiceOrderId: string;
  tableNumber: number;
  area: string;
  venueId: string;
};

class ReceiptRoute extends Route<ReceiptRouteParams> {
  getBase() {
    return "/receipt";
  }

  override template() {
    return `${this.getBase()}/:tableServiceOrderId`;
  }

  override route({ tableServiceOrderId, ...rest }: ReceiptRouteParams) {
    return `${this.getBase()}/${paths(tableServiceOrderId)}${queries(rest)}`;
  }
}

type PaymentRouteParams = {
  token: string;
};

class PaymentRoute extends Route<PaymentRouteParams> {
  getBase() {
    return "/payment";
  }

  override template() {
    return `${this.getBase}`;
  }

  override route(params: PaymentRouteParams) {
    return `${this.getBase()}/${queries({ token: params.token })}`;
  }
}

export enum Tab {
  Venues = "venues",
  Events = "greeter-events",
  Profile = "profile",
}

/**
 * Ionic routeInfo is buggy - Screw that shit,
 * discover the tab ourselves.
 *
 * This works for root level tabs.
 */
export function whichTab(path: string): Tab | undefined {
  const split = path.startsWith("/")
    ? path.slice(1).split("/")
    : path.split("/");

  if (split.length === 0) return;

  const supposedTab = split.at(0);
  let actualTab: Tab | undefined;

  switch (supposedTab) {
    case Tab.Venues:
      actualTab = Tab.Venues;
      break;
    case Tab.Events:
      actualTab = Tab.Events;
      break;
    case Tab.Profile:
      actualTab = Tab.Profile;
      break;
  }

  return actualTab;
}

export default class Routes {
  public static greeterEventsTab = class {
    static tabName = Tab.Events;

    public static readonly root = new GreeterEventsRoute(); // Root route
    public static readonly greeterEvents = this.root; // Alias for route
    public static readonly greeterEvent = new GreeterEventV2Route(this.tabName);
    public static readonly venue = new VenueRouteV2(this.tabName);
    public static readonly createBooking = new CreateBookingV2Route(
      this.tabName
    );
    public static readonly bookingSummary = new BookingSummaryRoute(
      this.tabName
    );
  };

  public static venuesTab = class {
    static tabName = Tab.Venues;

    public static readonly root = new VenuesRoute();
    public static readonly venues = this.root; // Alias for root
    public static readonly venue = new VenueRouteV2(this.tabName);
    public static readonly createBookingV2NewFlow =
      new CreateBookingV2NewFlowRoute(this.tabName);
    public static readonly createBooking = new CreateBookingV2Route(
      this.tabName
    );
    public static readonly createBookingV2 = new CreateBookingV3Route(
      this.tabName
    );
    public static readonly bookingSummary = new BookingSummaryRoute(
      this.tabName
    );
  };

  public static profileTab = class {
    static tabName = Tab.Profile;

    public static readonly root = new ProfileRoute();
    public static readonly profile = new ProfileRoute(); // Alias for root
    public static readonly updateUser = new UpdateUserRoute();
    public static readonly deleteUser = new DeleteUserRoute();
  };

  public static readonly venue = new VenueRoute(); // Should redirect to v2
  public static readonly venues = new VenuesRoute();
  public static readonly greeterEvent = new GreeterEventRoute();
  public static readonly greeterEventV2 = new GreeterEventV2Route();
  public static readonly greeterEvents = new GreeterEventsRoute();
  public static readonly home = new HomeRoute();
  public static readonly profile = new ProfileRoute();
  public static readonly createBooking = new CreateBookingRoute();
  public static readonly createBookingV2 = new CreateBookingV2Route();
  public static readonly bookingSummary = new BookingSummaryRoute();
  public static readonly login = new LoginRoute();
  public static readonly privacy = new PrivacyRoute();
  public static readonly outbound = new OutboundRoute();
  public static readonly updateUser = new UpdateUserRoute();
  public static readonly deleteUser = new DeleteUserRoute();

  // TODO: These should probably be its own tab or something. Or perhaps under venues.
  //       Undecided for now.
  public static readonly menu = new MenuRoute();
  public static readonly cart = new CartRoute();
  public static readonly receipt = new ReceiptRoute();
  public static readonly payment = new PaymentRoute();
}
